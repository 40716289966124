.fallbackComponent {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.errorImg {
  width: 249px;
  height: 256px;
}
.hintArea {
  display: flex;
  flex-direction: column;
  width: 272px;
  height: 256px;
  gap: 21px;
  color: rgba(0, 0, 0, 0.75);
}
.bugH1 {
  font-family: Inter;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 4px;
  font-weight: 700;
}
.bugSpan {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.links {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.linkTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.linkButton {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-decoration: underline;
  cursor: pointer;
  color: #47a1ad;
}
