
.guideModal {
  display: none;
}

.guideModalShow {
  display: block;
  pointer-events: visible;
  z-index: 10000;
}

.guideMask {
  pointer-events: auto;
  position: absolute;
  top: -50px;
  left: -65px;
  width: 230px;
  height: 149px;
  border-radius: 8px;
  box-shadow: 1px 1px 1px 2000px rgba(140, 140, 140, .7);
  z-index: 10;
}


.guideModal-2 .guideMask {
  width: 190px;
  left: -3px;
}

.guideModal-3 .guideMask {
  left: -6px;
  width: 246px;
}

.guideModal-4 .guideMask {
  top: 0;
  left: -8px;
  width: 1216px;
  height: 454px;
}

.guideModal-5 .guideMask {
  top: 454px;
  left: -8px;
  width: 608px;
  height: 454px;
}

.guideModal-6 .guideMask {
  top: 10px;
  left: -78px;
  width: 246px;
  height: 215px;
}

.guideModal-10000 .guideMask {
  top: 10px;
  left: -78px;
  width: 246px;
  height: 215px;
}

.guideInfo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 480px;
  padding: 15px 22px 22px 30px;
  margin-top: -122px;
  border-radius: 7px;
  box-sizing: border-box;
  background-color: var(--grey83);
  z-index: 11;
  font-weight: normal;
  cursor: default;
}

.guideInfo .arrow {
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  margin-top: -25px;
  border: 18px solid transparent;
}
.guideHeader{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  line-height: 20px;
}
.guideHeader .steps {
  color: var(--white);
  font-size: 14px;
}
.guideHeader .topCourseGuideTitle{
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
}

.guideHeader .close{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: var(--white);
  border-radius: 50%;
  cursor: pointer;
}
.guideHeader .closeIcon{
  font-size: 16px;
}
.guideModal-1 .guideInfo {
  margin-left: 120px;
}

.guideModal-1 .arrow {
  left: -40px;
  border-right: 30px solid var(--grey83);
}

.guideModal-2 .guideInfo {
  margin-top: -110px;
  margin-left: 140px;
}

.guideModal-2 .arrow {
  left: 50%;
  margin-left: -280px;
  border-right: 30px solid var(--grey83);
}

.guideModal-3 .guideInfo {
  margin-top: -110px;
  margin-left: 198px;
}

.guideModal-3 .arrow {
  left: 50%;
  margin-left: -280px;
  border-right: 30px solid var(--grey83);
}

.guideModal-4 .guideInfo {
  top: 605px;
  margin-left: -240px;
}

.guideModal-4 .arrow {
  left: 50%;
  margin-left: -20px;
  margin-top: -170px;
  border-bottom: 30px solid var(--grey83);
}

.guideModal-5 .guideInfo {
  top: 665px;
  margin-left: 30px;
}

.guideModal-5 .arrow {
  left: -40px;
  border-right: 30px solid var(--grey83);
}

.guideModal-6 .guideInfo {
  top: 128px;
  height: 182px;
  margin-left: -662px;
  padding: 20px;
}

.guideModal-6 .arrow {
  left: 465px;
  border-left: 30px solid var(--grey83);
}

.guideModal-6 .guideDescribeInfo {
  width: 440px;
  line-height: 24px;
  padding-left: -20px;
}

.guideModal-6 .guideBottom {
  height: 38px;
  padding-top: 20px;
}

.guideModal-10000 .guideInfo {
  top: 128px;
  height: 182px;
  margin-left: -662px;
  padding: 20px;
}

.guideModal-10000 .arrow {
  left: 465px;
  border-left: 30px solid var(--grey83);
}

.guideModal-10000 .guideDescribeInfo {
  width: 440px;
  line-height: 24px;
  padding-left: -20px;
}

.guideModal-10000 .guideBottom {
  height: 38px;
  padding-top: 20px;
}

.guideDescribeInfo {
  font-size: 16px;
  line-height: 22px;
  color: var(--white);
  text-align: left;
}

.guideDescribeInfo p {
  margin: 0;
  padding-top: 5px;
}

.guideBottom {
  display: flex;
  justify-content: flex-end;
  padding-top: 28px;
  line-height: 44px;
}

.guideBottom .buttons {
  display: flex;
}

.guideBottom .prevBtn {
  width: 84px;
  height: 44px;
  margin-left: 12px;
  background-color: #545970;
  border-radius: 4px;
  color: var(--white);
  font-size: 16px;
  text-align: center;
  line-height: 44px;
  cursor: pointer;
}

.guideBottom .nextBtn {
  width: 130px;
  height: 44px;
  margin-left: 12px;
  background: var(--pdfRead);
  border-radius: 4px;
  color: var(--white);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 44px;
  cursor: pointer;
}
.gotBtn {
  width: 106px;
  height: 38px;
  background-color: #545970;
  border-radius: 4px;
  color: var(--white);
  font-size: 16px;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
  margin-right: 354px;
}
.capableAddress{
  color: var(--primaryColor);
}
