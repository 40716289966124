.imgWrapper {
  position: absolute;
  z-index: 20
}

.meNowOffset {
  width: 140px;
  top: 16px;
  left: 414px;
}

.meNextOffset {
  width: 95px;
  top: 29px;
  left: 704px;
}

.imgWrapper > img {
  width: 100%;
}

.logoWrapper {
  position: relative;
}

.title {
  font-size: 50px;
  font-weight: bold;
  position: relative;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 11px;
  z-index: 50;
}

.pdfCompetenciesWrapper {
  margin: 0 0 20px;
}

.competencyWrapper {
  background: var(--grey10);
  margin-top: -11px;
  padding-left: 30px;
  border-radius: 8px;
  padding-bottom: 60px;
}

.subTitle {
  background: var(--pdfRead);
  font-size: 20px;
  font-weight: bold;
  color: var(--white);
  border-radius: 8px 8px 0 0;
  padding: 10px 0 10px 14px;
  margin-left: -30px;
}

.emptyMsg {
  font-size: 20px;
  color: var(--black);
  margin-top: 100px;
  text-align: center;
}

.emptySubMsg {
  font-size: 16px;
  text-align: center;
  color: var(--black);
  margin-bottom: 50px;
}
