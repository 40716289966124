@value primaryScrollbar from '../../globalStyles.module.css';

.assessSlider {
  position: relative;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  padding: 15px 0;
}

.mark {
  width: 35px;
  height: 35px;
  margin-top: -16px;
  margin-left: -16px;
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  cursor: pointer;
  outline: none;
}

.mark::before,
.mark::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 11px;
  left: 14px;
  background-color: transparent;
}

.markDefault::before {
  background-color: var(--grey41);
}

.mark:hover::before {
  box-shadow: 0 0 0 8px rgb(120, 220, 247);
}

.mark:hover::after {
  box-shadow: 0 0 0 16px rgba(120, 220, 247, 0.4);
}

.markExpected::before {
  box-shadow: 0 0 0 5px rgba(25, 182, 185, 0.3);
  background-color: rgba(25, 182, 185, 0.3);
}

.behaviour {
  max-height: 428px;
  overflow-y: auto;
  background-color: var(--white);
  color: var(--grey70);
}

.levelBehaviour{
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
}

.title {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}

.competencyName {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  flex:1;
  width: 0;
  color: var(--black);

}

.proficiencyLevel {
  width: auto;
  height:16px;
  font-size: 16px;
  line-height: 100%;
  border-radius: 27px;
  text-align: center;
  background-color: var(--competencyLevel);
  padding: 5px 11px;
  color: var(--black);
  font-weight: 600;
}

