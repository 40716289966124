.courseCard {
  display: flex;
  align-items: flex-start;
  width: 571px;
  height: 100px;

  background: var(--white);
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
}

.topCoursesImgContainer {
  height: 100%;
}

.topCoursesImg {
  width: 190px;
  height: 100px;
  object-fit: cover;
  border-radius: 7px 0 0 7px;
}

.topCoursesTextContainer {
  display: flex;
  height: 100%;
}

.courseInfo {
  display: flex;
  flex-direction: column;
  padding: 18px 0 0 10px;
}

.courseName {
  height: 40px;
  width: 313px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.coursesLink {
  text-decoration: none;
  color: var(--black);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 313px;
}

.enrolledInfo {
  height: 18px;
  margin: 8px 0 0 0;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: var(--grey70);
}

.courseFeedback {
  width: 18px;
  height: 18px;
  margin: 42px 0 0 19px;
  text-align: center;
  cursor: pointer;
}

.feedbackIcon {
  margin: 0;
  display: inline-block;
}

.topCoursesFeedbackContainer {
  display: flex;
  height: 100%;
}

.feedback {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  gap: 47px;
  padding-left: 40px;
  width: 281px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.selectedFeedbackItem {
  display: flex;
  align-items: center;
  height: 48px;
}

.notSelectedFeedbackItem {
  display: flex;
  align-items: center;
  height: 48px;
  cursor: pointer;
}

.relevantIcon {
  width: 22px;
  margin-right: 9px;
  margin-bottom: 2px;
}

.notRelevantIcon {
  width: 22px;
  margin-right: 9px;
  margin-top: 4px;
}

.close {
  display: flex;
  justify-content: center;
  width: 60px;
  cursor: pointer;
}

.closeIcon {
  display: inline-block;
  width: 13px;
}

