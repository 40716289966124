.headerInfo {
  width: 656px;
  padding-left: 22px;
  color: var(--black);
  font-size: 20px;
  box-sizing: border-box;
}

.link{
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  color: var(--magenta);
  border-bottom: 1px solid var(--magenta);
}
