.role {

}
.roleTitle {
  text-align: center;
  font-size: 16px;
  margin-top: -12px;
  color: var(--black3);
}
.roleTitle.largeItem {
  padding: 5px 0;
  font-weight: bold;
}
.archetypeItemWrapper {
  margin: 10px 0;
}

.splitLine {
  position: relative;

}
.splitLine:nth-child(odd)::after {
  content: '';
  display: block;
  height: calc(100% + 12px);
  width: 2px;
  background-color: var(--grey10);
  position: absolute;
  right: -9px;
  top: -4px;
}
@media (max-width: 1280px) {
  .splitLine:nth-child(odd)::after {
    background-color: transparent;
  }
}
