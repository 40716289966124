.archetypeItem.largeItem {
  height: 240px;
  background-position: -61px -18px;
  background-size: 214px;
}
.archetypeItem {
  height: 110px;
  margin: 0 auto;
  background-color: var(--black3);
  border-radius: 6px;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  background-image: url(/src/assets/Pictures/ArchetypesSelectPageBg.png);
  background-repeat: no-repeat;
  background-size: 96px;
  background-position: -29px -7px;
  transition: background-position 0.2s ease;
  font-size: 16px;
}
.archetypeItemName:focus {
  outline: none;
}
.archetypeItem:hover .archetypeItemMask {
  opacity: 1;
  background-position: calc(100% + 29px) -7px;
}
.archetypeItem:hover {
  background-position: calc(100% + 29px) -7px;
}
.archetypeItem.largeItem:hover .archetypeItemMask.largeItem {
  opacity: 1;
  background-position: calc(100% + 61px) -18px;
}
.archetypeItem.largeItem:hover {
  background-position: calc(100% + 61px) -18px;
}
.archetypeItem.selected {
  background-color: var(--primaryGreen);
}
.archetypeItem:focus {
  outline: none;
}

.boldText {
  font-weight: bold;
}

.archetypeItemMask {
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: var(--archetypeActive);
  position: absolute;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background-image: url(/src/assets/Pictures/ArchetypesSelectPageBg.png);
  background-size: 96px;
  background-position: -29px -7px;
  background-repeat: no-repeat;
  transition: background-position 0.2s ease;
}
.archetypeItemMask.largeItem {
  background-position: -61px -18px;
  background-size: 214px;
}

.inners {
  height: 84%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.inner {
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--white);
  text-decoration: none;
}
.inner:hover {
  background: rgba(0, 0, 0, 0.2);
}

.icon {
  width: 14px;
  height: 14px;
  margin: 0 9px 0 21px;
}
.text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
