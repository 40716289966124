.archetypeItem {
  background-image: url(/src/assets/Pictures/ArchetypesSelectPageBg.png);
  background-size: 125px;
  background-position: -34px -6px;
  background-repeat: no-repeat;
  transition: background-position 0.2s ease;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  height: 145px;
  border-radius: 6px;
  background-color: var(--primaryGreen);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  position: relative;
}
.archetypeItem:focus {
  outline: none;
}

.archetypeItem:hover {
  background-position: calc(100% + 34px) -6px;
}

.archetypeItem:hover .archetypeItemMask{
  opacity: 1;
  background-position: calc(100% + 34px) -6px;
}

.archetypeItemMask {
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: var(--archetypeActive);
  position: absolute;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background-image: url(/src/assets/Pictures/ArchetypesSelectPageBg.png);
  background-size: 125px;
  background-position: -34px -6px;
  background-repeat: no-repeat;
  transition: background-position 0.2s ease;
}

.inners {
  height: 84%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.inner {
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--white);
  text-decoration: none;
}
.inner:hover {
  background: rgba(0, 0, 0, 0.2);
}
.icon {
  width: 14px;
  height: 14px;
  margin: 0 9px 0 21px;
}
.text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
