.listGroup {
  display: flex;
  width: 100%;
  height: 110px;
}

.listGroupIndent {
  display: flex;
  width: 100%;
  height: 110px;
  box-sizing: border-box;
}
