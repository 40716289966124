.dashboard {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.UnClickDashboard{
  pointer-events: none;
}

.pdfModal {
  position: relative;
  top: 0;
  left: 0;
}
