.contactContent {
    height: 21px;
    width: 1308px;
    background-color: var(--black);
    color: var(--white);
    font-size: 14px;
    text-align: center;;
    padding-top: 30px;
    padding-bottom: 30px;
}

.contactContent a {
    text-decoration: none;
    color: var(--white);
}

.dividerLine {
    margin-left: 9px;
    margin-right: 11px;
    font-size: 10px;
    border-left: 1px solid var(--white);
}

.newThoughtworksLogo {
  position: relative;
  top: 3px;
  right: 1px;
  margin-left: -4.5px;
}
