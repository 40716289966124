.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--grey10);
}

.dotContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dotContainer:focus {
    outline: none;
}

.dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--grey80);
    margin: 0 1px;
}

.history {
    position: absolute;
    left: -40px;
    top: 32%;
    margin-top: 7px;
}

.history img {
    width: 60%;
    height: 60%;
}

.more {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -16px;
    cursor: pointer;
}

.progressBarWrap {
    width: 100%;
    height: 8px;
    background-color: var(--grey10);
}

.progressBar {
    height: 8px;
    border-radius: 0 4px 4px 0;
    background-color: var(--primaryColor);
}

.progressDes {
    font-size: 12px;
    margin-left: 8px;
    margin-top: 8px;
    color: var(--black3);
}

.progressDes > .progressFlag {
    font-size: 16px;
    color: var(--primaryColor);
}
.progressDes > span{
    vertical-align: super;
    margin-left: 5px;
}
.progressDes > .grayColor {
    color: var(--grey40);
}

.progressDes > .medalIcon {
    font-size: 16px;
    color: var(--black3);
}
.hexagonViewWrapper {
  text-align: center;
}

.mainContainer {
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.arrow {
    padding: 0 7px;
    color: var(--grey70);
}

.accomplishView {
    display: flex;
    justify-content: center;
    align-items: center;
}

.periodWrapper {
    font-weight: bold;
    margin: 15px 10px;
}

.period {
    color: var(--black3);
}

.description {
    color: var(--black3);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.period, .defaultPeriod, .descriptionWrapper {
    margin: 0 5px;
    font-weight: normal;
    padding: 10px;
    border-radius: 6px;
}

.descriptionWrapper {
    margin: 0;
    height: 110px;
    word-break: break-word;
}

.toolbar {
    margin-top: 20px;
    box-sizing: border-box;
    text-align: center;
}
.linkButton {
    margin: 0 15px;
    text-decoration: underline;
    color: var(--archetypeActive);
    cursor: pointer;
    font-size: 16px;
}
.linkButtonInner:focus {
    outline: none;
}
.time {
    position: relative;
    cursor: pointer;
}
.time:hover .tooltipContainer,.time:focus .tooltipContainer {
    display: block;
}

.tooltipContainer {
    display:none;
    top: 46px;
    z-index: 99;
    left: -45px;
    position: absolute;
    height: 65.5px;
    border-radius: 2px;
    box-shadow: 0 2px 8px 0 rgba(95, 95, 95, 0.26);
    background-color: rgba(0, 0, 0, 0.75);
}

.cardTitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tooltipContainer:before{
    display: block;
    content:'';
    position:absolute;

    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom:8px solid rgba(0, 0, 0, 0.75);
    left: 16px;
    top: -8px
}

.tooltipContainer p {
    font-size: 14px;
    color: white;
    font-weight: normal;
    margin: 0;
    padding: 8px 10px 0 10px;
    height: 20px;
    line-height: 20px;
    text-align: start;
}

.actionWrapper {
    top: 12px !important;
    left: -210px !important;
}

.deleteBtn {
    color: var(--errorRed) !important;
}

.deleteBtn, .unaccomplishedBtn{
    padding: 0 14px;
    line-height: 48px;
    min-width: 240px;
    background-color: var(--white);
}
.deleteBtn:hover, .unaccomplishedBtn:hover{
    background-color: var(--grey21);
}

.unaccomplishedBtn{
    border-bottom: solid 1px var(--grey10);
    color: var(--black3);
}


