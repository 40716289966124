.inputWrapper {
  display: flex;
  align-items: center;
  width: 230px;
  height: 46px;
  border: 1px solid var(--grey21);
  border-radius: 30px;
  background: var(--white);
  box-sizing: border-box;
}

.inputWrapper:hover {
  border-color: var(--black3);
}

.CompetenciesSearchBar {
  width: 312px;
}

.searchFocus {
  width: 412px;
}


.input {
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 16px;
  color: var(--black);
  background: var(--white);
  border: 0 none;
  border-radius: 30px;
  box-sizing: border-box;
}

.searchIcon {
  width: 22px;
  height: 22px;
  padding: 0 12px 0 24px;
}

.clearIcon {
  width: var(--space6);
  height: var(--space6);
  padding: 0 16px 0 10px;
  cursor: pointer;
}

