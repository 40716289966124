.warningWrapper{
  display: flex;
  width: 100%;
  border: 1px solid var(--orange);
  border-radius: 7px;
  background-color: #FEF4EA;
  margin: 37px 0;
}
.WrapperIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.3%;
  font-size: 32px;
  color: var(--orange);
}
.editWrapperIcon{
  width: 8.8%;
}
.warningText{
  width: 93%;
  font-size: 14px;
  line-height: 20px;
  margin: 24px 0;
}
.editWrapperText{
  width: 70.5%;
}
.warningTitle{
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
}
.editDisplay{
  display: none;
}
.affectedCompetencies{
  font-size: 14px;
  font-style: italic;
}
.affectedCompetencies b{
  font-style: normal;
}
.wrapperBtn{
  display: flex;
  padding: 13px 8px 0 0;
}
.wrapperBtn :hover{
  cursor: pointer;
}
.button{
  display: flex;
  border: none;
  background-color: #FEF4EA;
}
