.connectSummit {
  padding: 32px 0;
}
.circleArrow{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--grey21);
  color: var(--black3);
}
.summitButtonWrapper{
  display: flex;
  justify-content: flex-end;
  padding: 16px 0 20px;
}

.detailList{
  font-size: 16px;
  color: var(--black3);
  line-height: 22px;
}
.detailList p{
  margin: 0;
}

.titleInfo{
  display: flex;
  height: 32px;
  line-height: 32px;
}
.lampIcon{
  width: 32px;
  height: 32px;
  background-color: var(--white) ;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lampIcon > img{
  width: 22px;
}
.title{
  margin-left: 8px;
  border: 0 none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
}
