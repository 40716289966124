.headerFix {
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconWrapper {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    background-color: var(--white);
    color: var(--archetypeActive);
    display: flex;
    justify-content: center;
    align-items: center;
}
.main {
    height: 819px;
    width: 1039px;
}

.smaller {
  width: 850px;
  height: max-content;
}

.modal {
  background-color: var(--white);
  max-width: 100%;
  max-height: 100%;
  border-radius: 7px;
  outline: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  position: relative;
  height: 57px;
  line-height: 57px;
  background-color: var(--primaryColor);

  font-size: 20px;
  font-weight: bold;
  color: var(--grey80);
  display: flex;
}

.body {
  padding: 12px 0;
  flex: 1;
  height: 0;
  overflow-y: auto;
  box-sizing: border-box;
  margin: 10px 20px;
}
.noPadding {
  padding: 0;
}
.titleContent {
  flex: 1;
  width: 0;
  text-align: center;
}
