.competency {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
}

.competency:nth-child(2n-1) {
  background-color: var(--grey10);
}

.name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
}

.firstColumn {
  flex: 3;
  height: 100%;
  padding: 0 30px;
}

.secondColumn {
  flex: 4.7;
  height: 100%;
  border-left: 2px solid var(--grey10);
  border-right: 2px solid var(--grey10);
  padding: 0 30px;
}

.thirdColumn {
  flex: 3;
  height: 100%;
  padding: 0 30px;
}

.detailBtn {
  cursor: pointer;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.detailBtn:hover {
  text-decoration: underline;
}

.detailBtnInner:focus {
  outline: none;
}

.slider {
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.slider > div {
  width: 100%;
}

.checkBox {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
