.page {
  max-width: 1200px;
  margin: 0 auto;
}
.titleWrapper {
  padding-top: 20px;
}
.title {
  font-size: 20px;
  font-weight: bold;
  color: var(--black3);
  margin: 7px 0;
}
.description {
  font-size: 16px;
  color: var(--black3);
  margin: 5px 0;
}
.mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(232, 232, 232, .5);
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity .3s;
}
.mask.show {
  z-index: 1301;
  opacity: 1;
}
.marginLeft {
  margin-left: 30px;
}
.filterWrapper {
  text-align: center;
  display: inline-block;
  margin-bottom: 20px;
}
.selector {
  width: 220px;
}
.filterContainerWrapper {
  margin-top:20px;
}
.filterFillIconWrapper {
  display: inline-flex;
  align-items: center;
  position: relative;
  top: 5px;
}
.filterFillIcon {
  color: var(--primaryColor) !important;
}
.filterLabel {
  font-weight: bold;
}

.modalContent {
  display: flex;
  width: 560px;
  height: 280px;
}

.modalContent p {
  margin: 0 0 20px 10px;
}

.tipModalIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--grey41);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.tipModalIcon>img {
  height: 30px;
  width: 24px;
}

.noBoxShadow div:focus {
  box-shadow: none;
}

.reflectSearch{
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
