.footerContainer {
  --iconSize: 75px;
  --footerBackgroundColor: var(--primaryColor);
  --footerBorderColor: var(--white);
  margin-top: calc(var(--iconSize) / 2 + 60px);
  width: 100%;
  min-height: 279px;
  background-color: var(--footerBackgroundColor);
  padding-top: calc(var(--iconSize) / 2 + 10px);
  box-sizing: border-box;
  position: relative;
  color: var(--black3);
}

.footerFeedbackIconWrapper {
  height: var(--iconSize);
  width: var(--iconSize);
  border-radius: 50%;
  background-color: var(--footerBackgroundColor);
  position: absolute;
  top: calc(var(--iconSize) / 2 * -1);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerFeedbackIconInner {
  height: calc(var(--iconSize) - 10px);
  width: calc(var(--iconSize) - 10px);
  border-radius: 50%;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  outline: none;
}

.footerFeedbackIcon {
  font-size: 36px !important;
  color: var(--white);
}

.footerOtherApps {
  height: auto;
  display: flex;
  margin: 46px auto 0 auto;
  flex-wrap: wrap;
  justify-content: center;
}

.appIntranet {
  padding-left: 0;
  width: 300px;
  height: 100%;
  text-align: right;
  font-size: 16px;
  box-sizing: border-box;
  border-right: 1px solid var(--footerBorderColor);
}

.appFAQ,
.appCapable,
.dataPrivacy {
  height: 100%;
  padding-left: 21px;
  box-sizing: border-box;
  font-size: 16px;
}

.appFAQ {
  width: 300px;
  text-align: right;
  font-size: 16px;
  border-right: 1px solid var(--footerBorderColor);
}

.appCapable {
  width: 310px;
  border-right: 1px solid var(--footerBorderColor);
}

.dataPrivacy {
  width: 290px;
}

@media screen and (max-width: 1200px) {
  .footerOtherApps {
    height: auto;
  }
  .appIntranet,
  .appFAQ,
  .appCapable,
  .dataPrivacy {
    flex: none;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }
  .appIntranet {
    padding-left: 21px;
  }
  .appIntranet,
  .appFAQ {
    display: flex;
    justify-content: center;
  }
  .appCapable > div {
    display: flex;
    align-items: center !important;
  }
  .dataPrivacy {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .appIntranet,
  .appFAQ,
  .appCapable {
    border-right: none !important;
  }
  .appIntranet,
  .appFAQ > div {
    float: none;
    text-align: center !important;
  }
}

.appCapableLogoWrapper {
  position: relative;
  width: 113px;
  height: 45px;
  border-radius: 7px;
  background-color: var(--white);
  display: inline-block;
}

.appCapableLogoWrapper > img {
  width: 85px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.appCapable > div {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.appIntranet,
.appFAQ > div {
  text-align: left;
}

.appCapableLogoTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
}

.subTitle {
  font-size: 14px;
  color: var(--black);
}

.dataPrivacyBtn {
  cursor: pointer;
  color: var(--black3);
  font-size: 14px;
  font-weight: 600;
  margin-top: 17px;
  display: inline-block;
  border-bottom: 1px solid var(--black3);
}

.dataPrivacyBtnInner {
  color: var(--black);
  text-decoration: none;
}

.dataPrivacyBtnInner:focus {
  outline: none;
}

.footerTitle {
  font-size: 20px;
  text-align: center;
  color: var(--black);
}

.title {
  font-size: 16px;
  color: var(--black);
}

.text {
  margin-top: 10px;
  font-size: 14px;
  color: var(--black3);
}

.linkContent {
  display: inline-block;
  border-radius: 7px;
  background-color: var(--black);
  margin-top: 8px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  width: 192px;
  padding-top: 9px;
  padding-bottom: 12px;
  text-align: center;
}

.link {
  text-decoration: none;
}

.linkLeftText {
  color: var(--white);
}

.linkDividerLine {
  margin-left: 8px;
  margin-right: 8px;
  font-size: 13px;
  border-left: 1px solid var(--white);
}

.linkRightText {
  color: var(--pink1);
}

.contactContent {
  background-color: var(--black1);
  color: var(--white);
  font-size: 14px;
  text-align: center;
  padding-top: 19px;
  padding-bottom: 23px;
}

.contactContent a {
  text-decoration: none;
  color: var(--white);
}

.newThoughtworksLogo {
  position: relative;
  top: 3.5px;
  margin-left: -3px;
}

.dividerLine {
  margin-left: 9px;
  margin-right: 11px;
  font-size: 10px;
  border-left: 1px solid var(--white);
}

.primaryColor {
  color: var(--primaryColor);
}
.linkRed {
  color: var(--red1);
}
.policyContent {
  display: flex;
  margin-top: -16px;
  padding: 0 4px;
  overflow-y: auto;
  font-size: 14px;
}
.tipsIcon {
  width: 125px;
  height: 143px;
}

.policies {
  width: 646px;
  padding-top: 20px;
  padding-left: 20px;
  box-sizing: border-box;
}

.headerPolicy {
  margin: 0;
  padding-top: 10px;
  color: var(--black3);
  font-size: 16px;
  line-height: 22px;
}
.policyContent p {
  margin: 0;
  padding: 10px 0;
}
.policyContent ul {
  padding-left: 10px;
  list-style: inside;
  line-height: 20px;
}
.policyContent li::marker {
  font-size: 12px;
}
.policyContent li ul {
  padding-left: 30px;
}
.policyTable {
  padding-top: 20px;
  padding-bottom: 30px;
  color: var(--black3);
  font-size: 14px;
}
.policyTable p {
  margin: 0;
  padding: 0;
}
.tableHeader {
  display: flex;
  padding: 8px 12px;
  border-bottom: 2px solid var(--primaryColor);
  line-height: 20px;
}
.tableTh:first-child,
.tableTd:first-child {
  width: 295px;
  padding-right: 20px;
  box-sizing: border-box;
}
.tableTh:nth-child(2),
.tableTd:nth-child(2) {
  width: 288px;
}
.tableTr {
  display: flex;
  padding: 16px 12px 2px;
  border-bottom: 1px solid var(--primaryColor);
}
.tableTr:last-child {
  border: 0 none;
}
.securedInfo {
  padding-bottom: 30px;
}
.securedInfo ul {
  margin-bottom: 0;
}
.securedInfo p {
  padding-bottom: 0;
}
.modalButton {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 4px;
  box-sizing: border-box;
}
