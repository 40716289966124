.emptyCourseImgBoard {
  margin: 30px 0 18px 0;
  display: flex;
  justify-content: center;
}

.emptyCoursePrimaryDescription {
  margin: 0;
  font-weight: bold;
  text-align: center;
  font-size: 28px;
  line-height: 38px;
}

.emptyCourseSecondaryDescription {
  margin: 0;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
}
