.topCourseRecommendation {
  background-color: var(--white);
  padding-bottom: 20px;
  border-radius: 7px;
  height: 418px;
}
.info{
  width: 532px;
  height: 78px;
  padding: 56px 30px 0 30px;
}
.title {
  width: 314px;
  height: 20px;
  margin-left: 109px;
  margin-bottom: 10px;
  line-height: 20px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
}
.description{
  height: 48px;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}
.img{
  padding: 32px 260px 32px 260px;
}
.seeRecBtn{
  display: flex;
  width: 300px;
  height: 40px;
  margin-left: 146px;
  background: #212223;
  border-radius: 7px;
}
.link{
  padding: 8px 66px;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

