.myInterestGroup {
  overflow: hidden;
  width: 597px;
  min-height: 251px;
  border-radius: 7px;
  margin-left: 32px;
  background-color: var(--grey10);
}

.interestList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.interestItem {
  list-style-type: none;
  max-width: 90%;
  border-radius: 28px;
  background-color: var(--black);
  padding: 6px 15px;
  margin: 19px 0px 0px 11px;
  word-break: break-word;
  color: var(--white);
  font-weight: 600;
  font-size: 16px;
  width: fit-content;
}

.pdfInterestTitle {
  background: var(--pdfRead);
  font-size: 20px;
  font-weight: bold;
  color: var(--white);
  border-radius: 8px 8px 0 0;
  padding: 10px 0 10px 14px;
  margin: 0;
}

.emptyMsg {
  text-align: center;
  display: flex;
  height: calc(100% - 47px);
  align-items: center;
  justify-content: center;
}
