.formUrl {
  display: inline-block;
  padding: 0 4px;
  color: var(--red1);
}

.CompetenciesListSearchEmpty {
  padding: 32px 36px 44px;
}

.CompetenciesListSearchEmpty p {
  margin: 0;
  line-height: 28px;
}

.SkillsListSearchEmpty{
  padding: 0;
}

.SkillsListSearchEmpty p,.SkillsListSearchEmpty div{
  margin-bottom: 10px;
  line-height: normal;
}

.searchCategoryPageEmpty {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 692px;
  margin-top: 24px;
  border-radius: 21px;
  background-color: var(--white);
}

.searchCategoryPageEmptyIcon {
  padding: 105px 0 36px;
}

.searchCategoryPageEmpty p {
  width: 424px;
  text-align: center;
  margin: 16px 0;
}

.searchCategoryPageEmptyDes {
  width: 424px;
  text-align: center;
  line-height: 24px;
}

.missingCategoryTips {
  display: flex;
  padding: 50px 0 30px 0;
}

.missingCategoryTipsIcon {
  width: 44px;
  height: 44px;
  padding-right: 12px;
}

.missingCategoryTipsDes {
  flex: 1;
  padding-top: 10px;
  line-height: 24px;
}
