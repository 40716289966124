.headerWrapper {
  height: 85px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  background-color: var(--white);
}

.navigationHeader {
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.disableBack {
  justify-content: flex-end;
}

.backTo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  min-width: 220px;
}

.arrowLeftIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--grey10);
}

.cancelButton{
  width: 96px;
  line-height: 38px;
  font-size: 16px;
  text-transform: none;
  box-shadow: none;
  border: 1px solid #666666;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  left: 0;
}

.backToTitle {
  margin-left: 9px;
  font-size: 16px;
  color: var(--grey80);
}

.backTo:focus {
  outline: none;
}
