.capabilityItem{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 10px 16px 10px 20px;
  gap: 38px;
  font-size: 14px;
  box-sizing: border-box;
}
.itemInfo{
  display: flex;
  justify-content: space-between;
  width: 82%;
  height: 100%;
  gap: 20px;
}
.progress{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32%;
  height: 100%;
}

.slider{
  display: flex;
}

.progress:hover .slider{
  display: none;
 }

.label{
  display: none;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  background-color: var(--competencyFilterBackgroud);
}

.progress:hover .label{
  display: flex;
}

.progressItem{
  display: flex;
  align-items: center;
}

.circle{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--grey24);
}

.line{
  width: 16px;
  height: 2px;
  background-color: var(--grey24);
}

.checked{
  background-color: var(--primaryColor);
}

.name{
  display: flex;
  align-items: center;
  min-width: 0;
  width: 64%;
}
.ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.edit{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 28px;
  border-radius: 8px;
  gap: 5px;
}

.edit:hover{
  cursor: pointer;
  background-color: var(--grey21);
}

.pencil{
  width: 18px;
  height: 18px;
}
