.title {
  font-weight: bold;
}
.coreCapability {
  text-transform: capitalize;
  line-height: 20px;
}
.archetypes {
  line-height: 20px;
}
.PDFModal {
  font-size: 14px;
}
.PDFModal .subTitle{
  display: none;
}
.PDFModal .title{
  font-weight: normal;
}
