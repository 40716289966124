.group {
  margin-bottom: 20px;
}
.groupName {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  color: var(--grey80);
  margin: 8px 16px 2px 16px;
  border-radius: 12px;
  padding: 4px 4px 4px 2px;
}

.groupName:hover{
  background-color: var(--grey10);
}
.groupIcon {
  font-size: 24px !important;
  font-weight: bold;
  color: var(--grey80);
  margin: 2px 4px;
}
.item:nth-of-type(odd) {
  background-color: var(--grey10);
}
