.firstColumn {
  flex: 1 1 35%;
  height: 100%;
  margin-right: 2px;
}

.secondColumn {
  flex: 1 1 32%;
  height: 100%;
  margin-right: 2px;
}

.thirdColumn {
  height: 100%;
  flex: 1 1 20%;
  margin-right: 2px;
}
.fourthColumn{
  height: 100%;
  flex: 1 1 13%;
}
.Competencies,
.levelTitle,
.flexEnd,
.isLearningPriority{
  display: flex;
  align-items: center;
  padding-left: 35px;
  box-sizing: border-box;
  color: var(--black);
  background: var(--primaryColor);
}

.Competencies {
  padding-left: 20px;
  border-radius: 7px 0 0 0;
}

.flexEnd {
  border-radius: 0 7px 0 0;
  padding-left: 35px;
}

.title {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: 16px;
  font-weight: bold;
  height: 50px;
}

.latestCompetencies {
  display: flex;
  align-items: center;
  margin-left: 60px;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 5px;
  background-color: var(--magenta);
}

.wording {
  margin-left: 2px;
  font-weight: normal;
}

@media (max-width: 1024px) {
  .headerTitle {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    font-size: 10px;
    word-wrap: break-word;
  }
  .name {
    font-size: 12px;
  }
  .subtitle {
    font-size: 10px;
  }
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 94px;
  color: var(--black3);
  font-size: 20px;
}

.categoryTitle {
  padding-top: 16px;
  font-weight: bold;
  font-size: 16px;
  line-height: 36px;
  color: var(--black);
}

.markAs {
  display: flex;
  font-size: 16px;
  line-height: 22px;
}

.categoryEmpty {
  background-color: var(--white);
}
