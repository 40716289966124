.archetypeStyle{
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.circleArrow{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  color: var(--black3);
}
.archetypeIcon{
  position: absolute;
  width: 30px;
  height: 40px;
  left: 0;
  top: calc(50% - 40px/2);
}

.topCoursesItem{
  list-style-type: none;
}

.topCoursesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin: 0;
  padding: 20px 18px 30px 20px;
}

.skeletons{
  display: flex;
  gap: 10px;

  width: 570px;
  height: 100px;

  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
}

.skeletonTexts{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 19px 0;
}

