.showGuide{
  pointer-events: none;
}
.route {
  min-height: calc(100vh - 518px);
}

.errorPageHeight {
  min-height: 674px;
}
