.competenciesList {
  margin-top: 15px;
}

.firstColumn {
  flex: 3;
  height: 100%;
  padding: 0 30px;
}

.secondColumn {
  flex: 4.7;
  height: 100%;
  border-left: 2px solid var(--grey10);
  border-right: 2px solid var(--grey10);
  padding: 0 30px;
}

.thirdColumn {
  flex: 3;
  height: 100%;
  padding: 0 30px;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--grey80);
  color: var(--white);
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 7px 7px 0 0;
}

/* .header::after {
  content: ' ';
  position: absolute;
  top: -11px;
  left: 180px;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #2c3a47;
} */

.header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.suggestedLevel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
}

.suggestedLevel > div {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(25, 182, 185, 0.5);
  margin-right: 5px;
}

.body {
  padding: 20px 0;
}

.carouselPage {
  background-color: var(--white);
  margin: 10px 0;
  outline: 0;
}

.archetypeName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  background-color: var(--grey4);
}

.archetypeName p:nth-of-type(1) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--grey80);
  color: var(--white);
  font-size: 14px;
}

.errorMsg {
  font-size: 16px;
  color: var(--black3);
  text-align: center;
  margin-top: 20px;
}
.groupTitle {
  padding: 10px 30px;
  height: auto;
  margin: 0;
}
