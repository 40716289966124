.firstColumn {
  flex: 1 1 35%;
  height: 100%;
  margin-right: 2px;
}

.secondColumn {
  flex: 1 1 32%;
  height: 100%;
  margin-right: 2px;
}

.thirdColumn {
  height: 100%;
  margin-right: 2px;
  flex: 1 1 20%;
}
.fourthColumn{
  height: 100%;
  flex: 1 1 13%;
}
.reflectItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
}

.reflectItem:nth-child(2n),
.reflectItem:nth-child(2n) .slider,
.reflectItem:nth-child(2n) .learningPriority,
.reflectItem:nth-child(2n) .name {
  background-color: var(--white);
}

.reflectItem:nth-child(2n-1),
.reflectItem:nth-child(2n-1) .slider,
.reflectItem:nth-child(2n-1) .learningPriority,
.reflectItem:nth-child(2n-1) .name {
  background-color: var(--grey82);
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 5px;
  background-color: var(--magenta);
}

.name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  word-wrap: break-word;
  box-sizing: border-box;
}

.itemDescription  {
  cursor: pointer;
}
.itemDescription :hover {
  text-decoration: underline;
}

.slider {
  padding: 0 50px 0 35px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.slider > div {
  width: 100%;
}

.checkBox {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.learningPriority {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 40px;
  box-sizing: border-box;
  width: 100%;
}
.remove{
  display: flex;
  align-items: center;
  justify-content: center;
}


.removeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: var(--black);
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  line-height: 24px;
  cursor: pointer;
}

.subtitle {
  font-size: 14px;
  font-weight: 600;
  color: var(--grey80);
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.markAs {
  display: flex;
  font-size: 16px;
  line-height: 22px;
}

.removeTitle {
  margin: 2px 0;
}

.removeToolTip {
  position: relative;
}

.removeIconShow {
  visibility: visible;
}

.removeModalContent {
  width: 820px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--black);
}
.removeIconWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.removeIconWrapper:hover{
  cursor: pointer;
}

.highlight {
  position: relative;
}

.highlight::before {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  height: 100%;
  width: 8px;
  background-color: var(--primaryColor);
}

.informationIcon {
  margin-left: 5px;
  margin-top: 4px;
  cursor: pointer;
}
