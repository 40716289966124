.competencyDescribe {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.competencyGroupWrapper {
  margin-top: 20px;
  display: flex;
}

.competencyGroupContainer {
  height: 30px;
  line-height: 30px;
  flex: 1;
  width: 0;
}

.competencyGroupName {
  margin-right: 10px;
  display: inline-flex;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
  text-transform: capitalize;
}

.competencyGroupDot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 3px;
}

.competencyGroupLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--white);
  font-size: 12px;
}

.competencyGroupLinkIcon {
  color: var(--white);
  margin-left: 5px;
  font-size: 20px !important;
}

.competencyDes a {
  color: var(--white);
}
