@value primaryScrollbar from '../../globalStyles.module.css';

.header {
  max-width: 1200px;
  margin: 0 auto;
  height: 62px;
  line-height: 62px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.headerTitle {
  margin-left: 10px;
  font-size: 24px;
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .hideLogo {
    display: none;
  }
}
.thoughtWorksLogo {
  height: 46px;
  width: 300px;
  margin-bottom: -9px;
}

.pathwaysLogo {
  font-size: 40px !important;
  margin-top: -10px;
}

.headerLeft {
  height: 100%;
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
}

.headerLeft:focus {
  outline: none;
}

.pointer {
  cursor: pointer;
}

.headerRight {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.discover {
  margin-left: 20px;
}


.themeColorLogo {
  color: #26bdcf;
}

.headerLeftWithAfter {
  position: relative;
}

.headerLeftWithAfter:after {
  position: absolute;
  top: 52px;
  left: 8px;
  content: '';
  width: 0;
  height: 0;
  border: 14px solid transparent;
  border-bottom: 20px solid var(--grey4);
}
