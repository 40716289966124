.emptyCourseRec {
  width: 100%;
}

.emptyCourseImgBoard {
  display: flex;
  justify-content: center;
}

.emptyCourseImg {
  margin-top: 28px;
  width: 198px;
  height: 198px;
}

.emptyCoursePrimaryDescription {
  margin: 20px 0 0 0;
  font-weight: bold;
  text-align: center;
  font-size: 22px;
}

.emptyCourseSecondaryDescription {
  margin: 10px 0 28px 0;
  text-align: center;
  font-size: 16px;
}
