.page {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}
.titleWrapper {
  padding: 20px 0 20px 0;
  font-size: 16px;
  color: var(--black3);
}
.title {
  font-weight: 600;
  font-size: 20px;
}

.nodata {
  font-size: 16px;
  color: var(--black3);
  text-align: center;
  margin-top: 20px;
}

.selectOptionItem {
  font-size: 14px;
  font-weight: 600;
}

.selectDefaultOptionItem {
  font-size: 14px;
  font-weight: normal;
}
.expandSelected {
  color: var(--grey80) !important;
  background-color: var(--avocadoGreen) !important;
}
.expandSelected,
.selected {
  position: relative;
  height: 100%;
  min-width: 300px;
  width: auto;
  font-weight: 600;
  font-size: 16px;
  padding-right: 60px;
  overflow: hidden;
}

.competencyIcon {
  position: absolute;
  left: -36px;
  top: 25px;
  margin-top: -25px;
  width: 72px;
  height: 50px;
}

.noSelectMsg {
  margin-top: 200px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: var(--black3);
}
