.ovalWrapperInner {
  display: flex;
  align-items:center;
  cursor: pointer;
}
.ovalWrapperInner:focus {
  outline: none;
}
.oval {
  width: 40px;
  height: 40px;
  background-color: var(--grey41);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.oval>img {
  height: 30px;
  width: 24px;
}
.ovalText {
  font-size: 16px;
  color: var(--black3);
  margin-left: 10px;
  border-bottom: 1px solid #545454;
}
