.page {
  max-width: 1200px;
  margin: 10px auto;
}
.gridItem {
  min-height: 200px;
  background-color: var(--white);
  border-radius: 7px;
  box-shadow: 0 2px 2px 0 var(--grey21);

}
.gridItemTitle {
  height: 40px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: var(--grey21);
  color: var(--black3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
.titleWrapper {
  padding: 20px 0 10px 0;
  font-size: 16px;
  color: var(--black3);
}
.title {
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 3px;
}
.rolesWrapper {
  padding: 20px 10px 8px 10px;
  box-sizing: border-box;

}
.filterWrapper {
  margin: 20px 0;
  display: inline-block;
}

.underline {
  text-decoration: underline;
}

.tip {
  display: inline-block;
  float: right;
  margin-top: 20px;
}
.filterBar {
  margin-bottom: 8px;
}
