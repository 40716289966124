.toolsBar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  width: 100%;
  margin-top: 25px;
  padding-left: var(--space10);
  border-bottom: 4px solid var(--grey86);
  color: var(--grey80);
  font-size: 16px;
  border-radius: 4px;
  box-sizing: border-box;
}

.hidden {
  visibility: hidden;
}

.right {
  display: flex;
  align-items: flex-end;
  padding-bottom: var(--space3);
}

.lastUpdateTime {
  display: flex;
  justify-content: flex-start;
  height: 44px;
  padding: var(--space3) var(--space6) 0 0;
  box-sizing: border-box;
  color: var(--black3);
  font-size: 14px;
  line-height: 36px;
}

.lastUpdateTimeText {
  margin-left: 3px;
  color: var(--black3);
}

.splitLine {
  width: 1px;
  height: 44px;
  background-color: #C2C5D0;
}

.exportPDF {
  width: 136px;
  height: 38px;
  margin-left: var(--space6);
  border: 1px solid #666666;
  box-sizing: border-box;
  border-radius: 7px;
  color: var(--black3);
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}

.exportPDF:hover {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: var(--white);
}

.tabs {
  display: flex;
  height: 100%;
  text-align: center;
  font-size: 0;
}

.tabItem {
  position: relative;
  display: inline-block;
  width: 152px;
  height: 50px;
  color: var(--black3);
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  line-height: 50px;
}

.bottomLine {
  display: none;
  position: absolute;
  bottom: -4px;
  width: 152px;
  border-bottom: var(--space2) solid var(--primaryColor);
  border-radius: var(--space2);
}

.tabItem.active {
  font-weight: bold;
}

.tabItem.active .bottomLine {
  display: block;
}

.tabItem:not(.active):focus .bottomLine,
.tabItem:not(.active):hover .bottomLine {
  display: block;
  border-color: var(--competencyLevel);
}

.tabItemTitle:focus {
  outline: none;
}

@media not all and (min-resolution: .001dpcm) {
  @media {
    .tabItem:focus .tabItemTitle {
      outline: none;
    }

    .tabItem:focus {
      outline: none;
    }
  }
}

.dude {
  position: absolute;
  z-index: 2;
  height: 160px;
  width: 119px;
}


@media (max-width: 1199px) {
  .tabItem {
    width: 200px;
    font-size: 22px;
    height: 52px;
    margin: 0 5px;
  }
}

.historyIcon {
  color: var(--black3);
  vertical-align: bottom;
  font-size: 18px !important;
  margin-right: 4px;
}
