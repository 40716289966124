@value primaryScrollbar from '../../../../../../globalStyles.module.css';

.main {
  background-color: var(--white);
  width: 1000px;
  height: 1016px;
  max-width: 100%;
  max-height: 100%;
  border-radius: 7px;
  outline: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  position: relative;
  height: 57px;
  line-height: 57px;
  background-color: var(--primaryColor);
  /*border-top-left-radius: 7px;*/
  /*border-top-right-radius: 7px;*/
  font-size: 20px;
  font-weight: bold;
  color: var(--grey80);
  display: flex;
}

.backButton {
}
.titleContent {
  flex: 1;
  width: 0;
  text-align: center;
}
.backIcon {
  font-size: 26px !important;
  font-weight: 600;
  height: 40px !important;
  width: 40px !important;
  background-color: var(--grey21);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backIconInner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.backIconInner:focus {
  outline: none;
}
.iconWrapper {
  min-width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.iconWrapper:focus {
  outline: -webkit-focus-ring-color auto 1px;
}

.backText {
  font-size: 16px;
  font-weight: normal;
  text-indent: 10px;
  color: var(--grey80);
}
.body {
  padding: 12px 0;
  flex: 1;
  height: 0;
  overflow-y: auto;
  box-sizing: border-box;
  margin: 10px 20px;
}
.noPadding {
  padding: 0;
}
