@value primaryScrollbar from '../globalStyles.module.css';

.modal {
  background-color: var(--white);
  border-radius: 7px;
  height: 90%;
  outline: none;
  margin: 40px auto 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1500px;
}

.title {
  position: relative;
  width: 100%;
  height: 66px;
  background-color: var(--primaryColor);
  display: flex;
  justify-content: center;
}

.centralize {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: var(--grey80);
}

.close {
  position: absolute;
  right: 0;
  top: -3px;
  cursor: pointer;
}
.closeInner:focus {
  outline: none;
}

.pagination {
  margin: 11px 0;
  display: flex;
  height: 56px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pageTitle {
  font-size: 14px;
  color: var(--black3);
}

.pdfMeNow {
  margin-top: -160px;
  transform: scale(0.76);
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.linearGradient {
  width: 100%;
  height: 10%;
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.01),
    rgba(255, 255, 255, 0.78) 65%,
    #ffffff
  );
  z-index: 1;
}

.firstPage {
  height: 1851px;
}

.downloadButton {
  position: absolute;
  bottom: 4%;
  left: 50%;
  margin-left: -79px;
  z-index: 3;
}

.pdfPageWrapper {
  position: relative;
  flex: 1;
  overflow: scroll;
  padding-left: 98px;
  padding-bottom: 130px;
}

.pdfPage {
  width: 1308px;
  height: 1780px;
}

.pdfMain {
  margin: 0 40px 20px;
  height: calc(1780px - 172px - 81px - 50px);
}

.archetypesWrapper {
  display: flex;
  min-height: 250px;
  margin-top: 9px;
}

.pageNum {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 0 0 25px 0;
}

.maskWrapper {
  position: absolute;
  top: 66px;
  width: 100%;
  height: calc(100% - 66px);
  background: rgba(255, 255, 255, 0.65);
  z-index: 999;
  backdrop-filter: blur(20px);
}

.mask {
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 350px;
}

.progressWrapper {
  width: 488px;
  height: 7px;
  overflow: hidden;
  border-radius: 8px;
  background: var(--grey40);
}

.progress {
  width: 100%;
  height: 100%;
  background: var(--basicGreen);
  transition: all ease-in 0.5s;
}

.tips {
  margin-top: 30px;
}

.myPlanWrapper {
}

.header {
  background: var(--pdfRead);
  color: var(--white);
  margin: -30px -20px 0;
  display: flex;
  padding: 0 20px;
  align-items: center;
}

.header p {
  margin: 0;
}

.header .subTitle {
  font-size: 20px;
  font-weight: bold;
  border-right: 1px solid var(--white);
  line-height: 48px;
  width: 89px;
}

.timeWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  justify-content: center;
}

.time {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.time img {
  width: 14px;
  margin-right: 1px;
}

.myPlanTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: bold;
  margin: 15px 0 0;
}

.myPlanLogo {
  position: absolute;
  margin-left: 569px;
}

.goalWrapper {
  background: var(--white);
  margin-top: 20px;
  height: calc(1477px - 113px);
  position: relative;
  top: -52px;
}

.goal {
  width: 348px;
  padding: 30px 20px;
  background: var(--grey10);
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;
  margin-right: 30px;
  vertical-align: top;
}

.goal:nth-child(3) {
  margin-right: 0;
}

.competencyWrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.listTitle {
  font-size: 16px;
  font-weight: bold;
}

.listItem {
  font-size: 14px;
}

.gapWrapper {
  margin-top: 32px;
}
.growthWrapper li,
.gapWrapper li,
.actionWrapper li {
  margin: 10px 0;
  padding-left: 27px;
  position: relative;
  line-height: 20px;
  font-family: Menlo;
  word-wrap: break-word;
}
.growthWrapper li:before,
.gapWrapper li:before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--pdfRead);
  position: absolute;
  margin: 5px 0 0 -20px;
}
.growthWrapper,
.actionWrapper {
  margin-top: 32px;
}

.checkBoxWrapper {
  position: absolute;
  margin: -11px 0 0 -38px;
}

.done {
  font-weight: bold;
}

.emptyMsg {
  padding: 0px !important;
  text-align: center;
  font-style: italic;
  font-family: 'Open Sans', sans-serif !important;
}

.emptyMsg:before {
  content: '';
  opacity: 0;
}

.clearTopPadding {
  padding-top: 0;
}

.goalDesc {
  font-size: 14px;
  line-height: 20px;
  font-family: Menlo;
  word-wrap: break-word;
}

.msgWrapper {
  padding: 110px 0 120px;
  background: var(--grey10);
}

.msgWrapper > p {
  text-align: center;
  font-size: 20px;
}

.imgWrapper {
  position: absolute;
  z-index: 20;
}

.meNowOffset {
  width: 140px;
  top: 16px;
  left: 414px;
}

.meNextOffset {
  width: 95px;
  top: 29px;
  left: 704px;
}

.imgWrapper > img {
  width: 100%;
}

.logoWrapper {
  position: relative;
}

.meNowMeNextText {
  font-size: 50px;
  font-weight: bold;
  position: relative;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 11px;
  z-index: 50;
}
