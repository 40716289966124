
.close {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 35px;
  width: 35px;
  background-color: var(--grey10);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeInner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeInner:focus {
  outline: none;
}

.close:focus {
  outline: -webkit-focus-ring-color auto 1px;
}

.close:hover {
  background-color: var(--grey20);
}