.main{
  margin: 0 auto;
  max-width: 1200px;
}

.boatIcon{
  height: 176px;
  position: absolute;
  top: -20px;
  left: -8px;
}

.cardTitle{
  margin: 144px 0 7px 0;
  font-size: 20px;
  line-height: 1.5;
  color: var(--black3);
}

.module1Icon{
  height: 200px;
  position: absolute;
  right: -26px;
  top: 123px;
}

.module2Icon {
  position: absolute;
  top: -30px;
  height: 194px;
}

.module3Icon{
  position: absolute;
  top: -40px;
  height: 209px;
  left: 72px;
}

.link{
  text-decoration: none;
  cursor: pointer;
  color: var(--magenta);
  border-bottom: 1px solid var(--magenta);
}
