.helper{
  display: flex;
  flex-direction: column;
  width: 480px;
  padding-left: 80px;
  gap: 20px;
}

.logo{
  height: 44px;
  border-bottom: 1px solid var(--white);
}

.link{
  font-weight: 400;
  color: #C43067;
}

.helperContent{
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}
