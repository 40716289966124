@value primaryScrollbar from '../../../../globalStyles.module.css';

.myInterests {
  background-color: var(--white);
  height: 438px;
  border-radius: 7px;
  padding: 8px 30px 30px;
  box-sizing: border-box;
}
.myInterestsContainer {
  height: 256px;
  overflow-y: auto;
}
.title {
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 20px;
  color: var(--grey80);
}

.interestItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
  border-radius: 27.5px;
  background-color: var(--black);
  padding: 2px 5px;
  margin: 5px 0;
  word-break: break-word;
}

.interestItem span {
  font-size: 16px;
  color: var(--white);
  font-weight: 600;
  margin: 0 10px;
}

.deleteIcon {
  width: 24px;
  height: 24px;
  margin: 4px 0;
  background-color: var(--magenta);
  color: var(--white);
  border-radius: 50%;
  cursor: pointer;
}

.deleteIconInner:focus {
  outline: none;
}
