.page{
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
}

.title{
  margin-top: 60px;
}

.title b{
  width: 242px;
  height: 40px;

  font-weight: 700;
  font-size: 40px;
  line-height: 40px;

  color: var(--black);
}

.dividingLine{
  margin: 20px 0 10px 0;

  width: 120px;
  height: 8px;

  background: var(--magenta);
}

.description{
  margin: 0 0 30px 0;
  width: 1055px;
  height: 30px;

  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

.emptyMsgTitle {
  display: inline-block;
  width: 333px;
  text-align: center;
}

.otherArchetypes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 60px;
  margin-bottom: 40px;
}

.otherArchetypesGuide {
  line-height: 20px;
  font-size: 14px;
  text-align: center;
}
