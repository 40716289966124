.modal {
  width: 700px;
  border-radius: 7px;
  background-color: var(--white);
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.modal:focus {
  outline: none;
}
.header {
  height: 57px;
  width: 100%;
  line-height: 57px;
  background-color: var(--competencyGroupTitleBackgroud);
  font-size: 20px;
  font-weight: bold;
  color: var(--black3);
  display: flex;
}
.titleContent {
  flex: 1;
  width: 0;
  text-align: center;
  color: var(--black3);
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 40px;
  width: 40px;
  background-color: var(--grey21);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeInner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeInner:focus {
  outline: none;
}

.bodyContent {
  display: flex;
  padding: 16px 24px 16px 44px;
  justify-content: space-between;
}
.descriptionGroup {
  padding: 20px 0 0 0px;
  display: flex;
  flex-direction: column;
}

.description a {
  color: var(--black);
}

.textWrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 462px;
}
.competencyGroupWrapper {
  display: flex;
  justify-content: space-around;
  align-self: flex-end;
  width: 154px;
  margin-top: 30px;
  border-radius: 7px;
  border: 1px solid var(--competencyModalMoreLinkBorder);
}

.competencyGroupContainer {
  height: 30px;
  line-height: 30px;
  flex: 1;
  width: 0;
}

.competencyGroupName {
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
}

.competencyGroupDot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.competencyGroupLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 8px 10px;
  color: var(--black);
  font-size: 14px;
}

.competencyGroupLinkIcon {
  margin-left: 5px;
  font-size: 20px !important;
}

.hexagonGroup {
  display: flex;
  align-items: center;
}

.hexagonView {
  position: relative;
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  overflow: hidden;
  display: inline-block;
  width: 134px;
  height: 150px;
  background-color: var(--competencyHexagonBorder);
  cursor: default;
  transition: transform 0.3s;
}

.hexagonViewAfter {
  position: absolute;
  left: 2px;
  top: 2px;
  width: 130px;
  height: 146px;
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  z-index: -1;
  background-color: var(--competencyHexagonBackground);
}

.selected {
  background-color: var(--competencyHexagonSelectedBackground);
}

.hexagonContentGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 68px;
  padding: 39px 12px;
}

.hexagonContent {
  text-align: center;
  color: var(--black3);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
}

.hoverButton:hover + .hexagonView {
  background-color: var(--primaryGreen);
}
