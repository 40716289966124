.courseCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 262px;

  background: #ffffff;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
}

.topCoursesImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.emptyCourseImg {
  width: 258px;
  height: 258px;
}

.topCoursesImgContainer {
  width: 100%;
  height: 57%;
}

.topCoursesTextContainer {
  display: flex;
}

.topCoursesFeedbackContainer {
  display: flex;
}

.feedback {
  display: flex;
  flex-direction: column;
  padding: 10px 20px 6px 33px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 200px;
}

.selectedFeedbackItem {
  display: flex;
  align-items: center;
  height: 48px;
}

.notSelectedFeedbackItem {
  display: flex;
  align-items: center;
  height: 48px;
  cursor: pointer;
}

.relevantIcon {
  width: 23px;
  margin-right: 9px;
  margin-bottom: 4px;
}

.notRelevantIcon {
  width: 23px;
  margin-right: 9px;
  margin-top: 4px;
}

.close {
  width: 18px;
  height: 18px;
  margin-top: 21px;
  text-align: center;
  cursor: pointer;
}

.courseInfo {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 16px 10px;
  width: 77%;
}

.courseFeedback {
  width: 18px;
  height: 18px;
  margin: 21px 0 0 17px;
  text-align: center;
  cursor: pointer;
}

.feedbackIcon {
  margin: 0;
  display: inline-block;
}

.closeIcon {
  margin: 0;
  display: inline-block;
}

.enrolledInfo {
  margin-top: 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: var(--grey70);
}

.courseName {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  height: 60px;
}

.coursesLink {
  text-decoration: none;
  color: var(--black);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 225px;
}
