.title{
  font-size: 16px;
  color: var(--black3);
  margin: 10px 0;
}

.adjustWrapper {
  display: flex;
  align-items: flex-end;
  padding-bottom: 20px;
}
.assessWrapper {
  flex: 1;
  width: 0;
  padding-right: 40px;
  padding-left: 10px;
  box-sizing: border-box;
}
.proficiencyLevelWrapper {
  padding: 0;
  list-style: none;
  display: flex;
  font-size: 16px;
  left: -40px;
  width: calc(100% + 100px);
  position: relative;
  text-align: center;
  margin: 14px 0 8px 0;
}
.proficiencyLevelItem {
  flex: 1;
  width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkboxWrapper {
  display: flex;
  box-sizing: border-box;
  width: 300px;
  height: 44px;
  padding: 10px 0 10px 20px;
}

.line {
  position: absolute;
  top: 158px;
  width: 66px;
  border-right: 2px solid var(--primaryGreen);
  bottom: -24px;
}
