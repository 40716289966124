.learnCourse{
  position: relative;
  height: 478px;
  margin-top: 24px;
  border-radius: 7px;
  background-color: var(--white);
  background-image: url("../../../../assets/Icons/courseIcon.png");
  background-position: 33px 40px;
  background-repeat: no-repeat;
}
.courseTitle{
  padding: 12px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.learnCourse p{
  margin: 0;
  padding: 0 12px;
  font-size: 16px;
  line-height: 22px;
}
.courseBtns{
  position: absolute;
  right: 60px;
  bottom: 20px;
}
.courseBtns .tip{
  padding-top: 8px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
.selfReflectBtn{
  text-decoration: none;
}

.selfReflectBtn:focus {
  outline: none;
}
