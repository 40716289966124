.journey{
  padding-top: 50px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  height: 746px;
}

.wording{
  margin-top: -2px;
}

.newThoughtworksLogo {
  margin-left: 5px;
}

.twLogo{
  font-size: 16px;
  width: 97px;
  margin: 0 0 -3px  4px;
}

.journeyTitle{
  margin: 0;
  padding: 10px 0 10px 0;
  font-size: 40px;
  line-height: 56px;
}

.journey > p{
  max-width: 541px;
  margin: 0;
  padding-top: 12px;
  font-size: 20px;
  line-height: 28px;
}

.journeyBg{
  margin-top: -220px;
  margin-left: 30px;
}

.journeyBtns{
  position: absolute;
  top: 82%;
  right: 60px;
}

.selfReflectBtn{
  text-decoration: none;
}

.selfReflectBtn:focus {
  outline: none;
}

.tellMeMore{
  text-align: center;
  margin-top: 16px;
  text-decoration: none;
  display: block;
  color: var(--black);
  cursor: pointer;
}

.tellMeMore img{
  width: 30px;
  height: 19px;
  margin-right: 10px;
  margin-bottom: -6px;
}

.twLogoWrapper {
  display: flex;
  height: 20px;
}

@media screen and (max-width: 1200px){
  .twLogoWrapper {
    margin-bottom: -10px;
  }

  .journey h2, .twLogoWrapper{
    padding: 0 25px;
  }

  .journey > p{
    padding: 0 25px;
    max-width: 441px;
  }

  .journeyBg{
    width: 90%;
    margin-left: 5%;
    margin-top: -140px;
  }

  .journeyBtns{
    top: 82%;
  }
}
@media screen and (max-width: 1080px) {
  .journey {
    height: 678px;
  }
}
@media screen and (max-width: 900px){
  .journey {
    height: 640px;
  }

  .journeyBg{
    margin-top: -80px;
  }

  .journeyBtns{
    top: 84%;
  }
}
@media screen and (max-width: 800px) {
  .journey {
    height: 602px;
  }}
