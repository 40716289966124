.skillPod {
  display: inline-block;
  margin: 7px;
  border-radius: 999px;
  border: none;
  padding: 7px 14px;
  line-height: 20px;
  font-size: 16px;
  color: white;
  background-color: var(--black3);
}

.twoColumn {
  padding: 16px 24px;
  display: flex;
}

.twoSubColumn {
  display: flex;
  padding: 20px 0;
}

.twoSubColumn:not(:last-child) {
  border-bottom: 1px solid #d4d7da;
}

.imgContainer {
  margin-right: 15px;
}

.descContainer {
  flex: 1;
  padding-top: 10px;
}
.name{
  font-size: 16px;
}
.skillContainer {
  max-height: 185px;
  overflow-y: scroll;
}

.newLabel {
  display: inline-block;
  border-radius: 4px;
  background-color: var(--red1);
  padding: 1px 5px;;
  color: white;
  font-weight: bold;
  margin-right: 5px;
}

.highlighted {
  color: var(--red1);
  font-weight: bold;
}
.buttonContainer {
  margin: 10px 24px 24px;
  display: flex;
  justify-content: flex-end;
}
.missTips{
  width: 553px;
  padding-top: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid #D4D7DA;
  font-size: 14px;
}
.listLink{
  color: var(--red1);
  text-decoration: underline;
  cursor: pointer;
}
.paragraphTitle,
.paragraphMessage{
  font-size: 16px;
}
.paragraphMessage{
  margin: 0;
  padding-bottom: 24px;
}
