.hexagonItemContainer {
    --hexWidth: 140px;
    --hexHeight: 160px;
    --gapWidth: 10px;
    margin: 0 auto;
    font-size: 0;
    padding: 10px 0 40px 0;
}

.hexagonItem {
    display: inline-block;
    position: relative;
    width: var(--hexWidth);
    height: var(--hexHeight);
    margin-left: var(--gapWidth);
    margin-bottom: calc((calc(0px - var(--hexHeight)) / 4) + var(--gapWidth));
    background-color: var(--grey40);
    -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    cursor: default;
    transition: transform .3s;
    pointer-events: none;
}
.hexagonItem::before {
    content: "";
    background-color: var(--white);
    left: 2px;
    top: 2px;
    right: 2px;
    bottom: 2px;
    position: absolute;
    z-index: -1;
}

.hexagonItem, .hexagonItem::before {
    -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
}

.hexagonItemBody {
    height: calc(var(--hexHeight) / 2);
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    color: var(--grey80);
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
}
.hexagonItem.inherit,
.hexagonItem.inherit::before {
    background-color: var(--grey80);
}
.hexagonItem.inherit .competencyName {
    color: var(--white);
}

.hexagonItem.based {
    background-color: var(--grey40);
}
.hexagonItem.based::before {
   background-color: var(--grey21);
}
.hexagonItem.based .competencyName {
    color: var(--grey80);
}
.hexagonItem.inherit .hexagonItem.based {
    color: var(--grey80);
}
.hexagonItemContainer.extraLarge {
    width: 1280px;
}
.hexagonItemContainer.large {
    width: 1000px;
}
.hexagonItemContainer.medium {
    width: 836px;
}
.hexagonItemContainer.extraMedium {
    width: 684px;
}
.hexagonItemContainer.small {
    width: 540px;
}
.hexagonItemContainer.extraSmall {
    width: 380px;
}
.hexagonItemContainer.mini {
    width: 200px;
}
.hexagonItemContainer.extraLarge > .hexagonItem:nth-child(16n + 9),
.hexagonItemContainer.large > .hexagonItem:nth-child(12n + 7),
.hexagonItemContainer.medium > .hexagonItem:nth-child(10n + 6),
.hexagonItemContainer.extraMedium > .hexagonItem:nth-child(8n + 5),
.hexagonItemContainer.small > .hexagonItem:nth-child(6n + 4),
.hexagonItemContainer.extraSmall > .hexagonItem:nth-child(4n + 3),
.hexagonItemContainer.mini > .hexagonItem:nth-child(2n + 2)
{
    --marginLeft: calc(var(--hexWidth)/2) + calc(var(--gapWidth)/2);
    margin-left: calc(var(--marginLeft) + var(--gapWidth))
    /*margin-left: calc(calc(var(--hexWidth) / 2) + calc(var(--gapWidth) / 2) + var(--gapWidth));*/
}

.competencyName {
    min-height: 70%;
    padding: 0 10px;
}
.proficiencyLevel {
    margin-top: 3px;
    color: var(--black);
    width: 100%;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
}
.proficiencyLevel>span {
    background-color: var(--competencyLevel);
    padding: 1px 6px;
    border-radius: 5px;
}
.emptyMessage {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
}
