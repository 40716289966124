
.capability{
  padding: 20px 24px;
  background: var(--white);
  border-radius: 7px;
  margin-top: 33px;
  position: relative;
  min-height: 469px;
  box-sizing: border-box;
  text-align: center;
}

.capabilityTitle {
  display: flex;
  align-items: center;
  margin-left: -6px;
}

.capabilityTitle span{
  font-size: 20px;
  color: var(--black);
  margin-left: 8px;
}

.pathwaysCapableIcon {
  height: 470px;
  position: absolute;
  top: 50px;
  left: 80px;
}

.capabilityDescription{
  width: 532px;
  margin-left: 622px;
  margin-right: 22px;
  text-align: left;
  margin-top: -12px;
}

.capabilityDescription p{
  margin-top: 20px;
}

.link{
  text-decoration: none;
  cursor: pointer;
  color: var(--magenta);
  border-bottom: 1px solid var(--magenta);
}

@media screen and (max-width: 1200px){
  .pathwaysCapableIcon {
    position: static;
    height: 400px;
  }
  .capabilityDescription{
    margin-left: 0;
    width: 100%;
    margin-top: -30px;
  }
}
