@value primaryScrollbar from '../../../../globalStyles.module.css';

.competencies {
  position: relative;
  background-color: var(--white);
  border-radius: 7px;
  height: 438px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.mask{
  position: absolute;
  bottom: 0;
  left: 0;
  right:2px;
  width: 99%;
  height: 48px;
  z-index: 3;
  pointer-events: none;
  background:linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}


