.ownGoalView {
  width: 144px;
  height: 143px;
  overflow: hidden;
  border-radius: 7px;
  border: solid 2px var(--grey70);
  background: url("../../../../../assets/Icons/Diana.svg") no-repeat center 14px;
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 10px;
}

.ownGoalName {
  width: 180px;
  margin-top: 117px;
  margin-left: -18px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  transform:scale(0.9);
}
.competencyLevel {
  font-size: 11px;
  text-align: center;
  background-color: var(--competencyLevel);
  padding: 1px 6px;
  border-radius: 5px;
}
.grayBgColor {
  background-color: var(--grey40);
}

.hexagonView {
  position: relative;
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  overflow: hidden;
  display: inline-block;
  width: 143px;
  height: 157px;
  background-color: var(--grey70);
  cursor: default;
  transition: transform .3s;
}

.hexagonViewAfter {
  position: absolute;
  left: 2px;
  top: 2px;
  width: 139px;
  height: 153px;
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  z-index: -1;
  background-color: var(--white);
}

.hexagonContent {
  width: 143px;
  height: 80px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: var(--black3);
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
}

.hexagonContent > .grayBgColor {
  background-color: var(--grey21);
}

.competencyName {
  min-height: 70%;
  padding: 0 10px;
  margin-bottom: 3px;
}
