.pdfHeader {
    width: 1308px;
    display: flex;
    flex-direction: row;
    height: 172px;
}
.left {
    width: 408px;
    background-color: var(--primaryColor) ;
}

.middle {
    width: 450px;
    background-color: var(--competencyLevel) ;
    display: flex;
    flex-direction: row;
}

.conversationsIcon {
    width: 48px;
    height: 48px;
    margin-top: 35px;
    margin-left: 38px;
    margin-right: 13px;
}

.middleDescription {
    font-size: 16px;
    margin-top: 41px;
    width: 306px;
    height: 111px;
}

.right {
    width: 450px;
    background-color: var(--grey10) ;
    display: flex;
    flex-direction: row;
}

.headerLeft {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    margin-top: 43px;
    margin-left: 49px;
}

.pathwaysLogo {
    font-size: 30px !important;
    margin-top: -10px;
}

.logoTitle {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
}

.hidden {
    visibility: hidden;
}

.title {
    font-size: 30px;
    margin-left: 49px;
    font-weight: bold;
}

.lastUpdateTime {
    display: flex;
    justify-content: flex-start;
    font-weight: 500;
    margin-left: 49px;
    color: var(--black3);
    font-size: 14px;
    padding-bottom: 3px;
    box-sizing: border-box;
}

.historyIcon {
    color: var(--black3);
    vertical-align: bottom;
    font-size: 18px !important;
    margin-right: 4px;
}

.lastUpdateTimeText {
    margin-left: 3px;
    color: var(--black3);
}

.user {
    margin-top: 41px;
    width: 294px;
    height: 94px;
    font-size: 14px;
}

.userName {
    font-size: 16px;
    font-weight: bold;
}


