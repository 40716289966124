.errorPage{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.errorIcon{
  margin-top: 41px;
}
.errorTitle{
  margin-top: -107px;
  font-size: 16px;
  font-weight: bold;
}
.homePageButton{
  margin-top: 56px;
}


