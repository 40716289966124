.modal {
  width: auto;
  height: auto;
  background-color: var(--white);
  border-radius: 7px;
  outline: none;
  overflow-y: scroll;
}
.competencyAndArchetypeTipModal,
.tipsToHelpYouDecideModal
{
  width: auto;
  max-height: 910px;
  background-color: var(--white);
  border-radius: 7px;
  outline: none;
  overflow-y: scroll;
}
.modal::-webkit-scrollbar {
  width: 0;
}
.title {
  position: relative;
  width: 100%;
  height: 57px;
  background-color: var(--primaryColor);
  color: var(--black3);
  text-align: center;
  font-weight: bold;
  line-height: 57px;
  margin-top: 0;
}
.title h2 {
  padding: 0;
  margin: 0;
  font-size: 20px;
}
.content {
  padding: 40px 20px 39px;
  flex-direction: column;
  display: flex;
  align-items: center;
  outline: none;
}
.desc {
  margin: 0 0 10px;
  font-size: 16px;
  color: var(--black3);
}
.contentWrapper {
  display: flex;
  margin-top: -16px;
  padding: 0 4px;
}
.logoContent {
  width: 125px;
  height: 143px;
}
.mainContent {
  width: 648px;
  color: var(--black);
  box-sizing: border-box;
  padding-left: 24px;
}
.paragraph{
  font-size: 16px;
  padding-bottom: 40px;
}
.paragraph h4,
.paragraph p{
  margin: 0;
  padding: 0;
  line-height: 22px;
}
.paragraph h4{
  padding-bottom: 10px;
}
.operateButtons{
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: -5px;
}

/*
competencyAndArchetypeTipModal
 */
.competencyAndArchetypeTipModal{
  height: 100%;
  overflow-y: initial;
}
.competencyAndArchetypeTipModalContent{
  height: 82%;
  overflow-y: scroll;
}
.competencyAndArchetypeTipModalContentWrapper{
  display: block;
}
.competencyAndArchetypeTipModalHeaderWrapper{
  display: flex;
  align-items: center;
  padding: 7px 10px 10px;
}
.competencyAndArchetypeTipModalLogoContent{
  width: 184px;
  height: 204px;
}
.competencyAndArchetypeTipModalHeaderContent {
  width: 656px;
  padding-left: 22px;
  color: var(--black);
  font-size: 20px;
  box-sizing: border-box;
}
.competencyAndArchetypeTipModalMainContent{
  width: 812px;
  margin: auto;
  padding-left: 0;
  border-top: 1px solid #D4D7DA;
}
.competencyAndArchetypeTipModalParagraph{
  padding-bottom: 0;
}
.competencyAndArchetypeTipModalParagraph ul{
  padding: 0;
  margin: 0;
}
.competencyAndArchetypeTipModalParagraph li{
  padding-top: 10px;
}
.competencyAndArchetypeTipModalParagraph h4{
  padding: 35px 0 10px;
  font-weight: 700;
}
.competencyAndArchetypeTipModalParagraph p{
  padding-top: 20px;
}
.competencyAndArchetypeTipModalParagraph:first-of-type p{
  padding-top: 10px;
  padding-left: 25px;
}
.bottomWrapper{
  padding-top: 20px;
  padding-bottom: 20px;
}
.bottomLink{
  color: var(--pdfRead);
  cursor: pointer;
  border-bottom: 1px solid var(--magenta)
}

/* ArchetypesSelectPageModal
 */
.archetypesSelectPageModalDesc{
  margin: 0;
}
.archetypesSelectPageModalLogoContent{
  display: flex;
  width: 68px;
  height: 68px;
  background-color: var(--grey10);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}
.archetypesSelectPageModalLogoContent .archetypesSelectPageModalImageComponent{
  width: 60%;
  height: auto;
  color: var(--primaryColor);
}
.archetypesSelectPageModalContentWrapper{
  display: flex;
  padding: 7px 48px 0 46px;
  margin-top: 0;
}
.archetypesSelectPageModalMainContent{
  width: 585px;
  margin-top: 2px;
  padding-left: 0;
}
.archetypesSelectPageModalParagraph{
  padding-bottom: 30px;
}
.archetypesSelectPageModalParagraph:last-of-type{
  padding-bottom: 36px;
}
.archetypesSelectPageModalParagraph:first-of-type h4{
  margin: 10px 0 20px 0;
  font-size: 20px;
  font-weight: bold;
}
.archetypesSelectPageModalParagraph h4,.archetypesSelectPageModalParagraph p{
  line-height: normal;
}
.archetypesSelectPageModalParagraph  a{
  border-bottom: 1px solid var(--black);
  color: var(--magenta);
  text-decoration: none;
}
.archetypesSelectPageModalOperateButtons{
  margin-bottom: 11px;
}

/* competenciesSelectPageModal */
.competenciesSelectPageModalContentWrapper{
  box-sizing: border-box;
  height: 410px;
  width: 556px;
  padding: 0 10px 0 0;
  overflow-y: scroll;
  display: flex;
  margin-top: 0;
}

.competenciesSelectPageModalLogoContent{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--grey41);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 10px;
}
.competenciesSelectPageModalLogoContent > img{
  height: 30px;
  width: 24px;
}

.competenciesSelectPageModalMainContent{
  padding: 0;
}
.competenciesSelectPageModalParagraph{
  color: var(--grey80);
  padding-bottom: 30px;
}
.competenciesSelectPageModalParagraph p{
  line-height: normal;
}
.competenciesSelectPageModalParagraph:first-of-type{
  margin-top: 10px;
}
.competenciesSelectPageModalParagraph:nth-of-type(3) > p > p{
  margin-top: 5px;
}
.competenciesSelectPageModalOperateButtons{
  padding-top: 20px;
  margin-bottom: 0;
}
