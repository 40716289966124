.timeInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.createTimeInfo, .updateTimeInfo {
  font-size: 14px;
  color: var(--black3);
}

.createTimeInfo img, .updateTimeInfo img {
  width: 19.1px;
  height: 19.1px;
  vertical-align: sub;
  margin-right: 6px;
}
