.archetypesGroup {
  overflow: hidden;
  width: 597px;
  min-height: 251px;
  border-radius: 7px;
  box-shadow: 0 2px 2px 0 var(--grey21);
  border: solid 1px var(--grey21);
  margin-top: 28px;
  margin-left: 38px;
  background-color: var(--grey10);
}

.archetypesGroupTitle {
  position: relative;
  color: var(--white);
  background: var(--pdfRead);
  font-size: 16px;
  font-weight: bold;
  padding: 14px 13px;
}

.archetypeWrapper {
  flex: 1;
  background: var(--grey10);
  border-radius: 8px;
}

.archetypeItem {
  background-image: url(/src/assets/Pictures/ArchetypesSelectPageBg.png);
  background-size: 125px;
  background-position: -34px -6px;
  background-repeat: no-repeat;
  padding: 0 10px;
  box-sizing: border-box;
  width: 150px;
  height: 135px;
  border-radius: 6px;
  background-color: var(--black3);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  position: relative;
  margin-right: 30px;
  margin-top: 30px;
}

.archetypesList {
  display: flex;
  padding-left: 30px;
  padding-bottom: 30px;
  flex-wrap: wrap;
}

.emptyArchetypes {
  height: 203px;
  font-size: 20px;
  text-align: center;
  line-height: 203px;
}

.pdfArchetypeTitle {
  background: var(--pdfRead);
  font-size: 20px;
  font-weight: bold;
  color: var(--white);
  border-radius: 8px 8px 0 0;
  padding: 10px 0 10px 14px;
  margin: 0;
}

.emptyMsg {
  text-align: center;
  display: flex;
  height: calc(100% - 47px);
  align-items: center;
  justify-content: center;
}
