.groupHexagonWrapper {
  box-sizing: border-box;
}

.groupName {
  display: inline-flex;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  color: var(--grey80);
  margin: 10px 20px;
}

.item:nth-of-type(odd) {
  background-color: var(--grey10);
}
