.selectorWrapper{
  position: relative;
  display: inline-flex;
}

.iconElement {
  position: absolute;
  pointer-events: none;
  height:100%;
  right: 0;
  width: 48px;
  background-color: var(--grey21);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconWrapper {
  height: 22px;
  width: 22px;
  box-sizing: border-box;
  background-color: var(--grey80);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  color: var(--white)!important;
}

.optionDivider {
  border-top: dashed 1px var(--grey21) !important;
  background-color: var(--white) !important;
}

.itemText {
  flex: 1;
  margin-right: 8px;
  flex-grow: 0 !important;
  min-width: auto;
  max-width: 450px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.itemText .MuiTypography-body1{
  font-weight: inherit;
}

.myOwnGoalOptionText {
  color: var(--black3);
  font-size: 16px;
  margin-right: 8px;
}

.pencilContent {
  width: 26px;
  height: 26px;
  line-height: 26px;
  background-color: var(--grey21);
  text-align: center;
  border-radius: 13px;
  margin-top: 2px;
}

.pencilIconSize {
  font-size: 16px !important;
  color: black;
}

.noBoxShadow div:focus {
  box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12);
}

.disabledSelect{
  opacity: 0.5;
}
.selectorLabel{
  display: inline-block;
  position: relative;
}
.selectorLabel:after{
  content: '';
  position: absolute;
  left: -4px;
  bottom: 8px;
  width: 100%;
  height: 1px;
  padding: 0 4px;
  background-color: var(--white)
}
.selectorLabel span{
  position: relative;
  z-index: 10;
}
.test{
  top: 271px
}
