.contentTitle{
  display: flex;
  align-items: center;
}
.iconSpanInner{
  display: flex;
  align-items: center;
  width: 20px;
  padding: 2px 0 0 5px;
}
.iconSpan {
  font-size: 20px;
  display: inline-block;
  line-height: 20px;
  vertical-align: middle;
}

.iconSpanInner:focus {
  outline: none;
}

.informationIcon {
  cursor: pointer;
  font-size: 20px !important;
  vertical-align: bottom;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  line-height: 60px;
  border-bottom: solid 1px var(--grey21);
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: var(--grey80);
}

.iconWrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.add{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93px;
  height: 32px;
  gap: 2px;
  margin-right: 15px;
  color: var(--red1);
  text-decoration: none;
}

.edit{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 32px;
  gap: 2px;
  color: var(--grey80);
  text-decoration: none;
}

.add:hover,.edit:hover{
  background-color: var(--grey10);
  border-radius: 6px;
}
