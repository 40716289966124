.preference{
  display: flex;
  flex-direction: column;
}

.title{
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.items{
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-top: 10px;
}

.item{
  display: flex;
  gap: 8px;
  align-items: center;

  box-sizing: border-box;
  max-width: 100%;
  width: 560px;
  height: 80px;
  padding: 20px 32px 20px 20px;

  background-color: var(--white);
  border-radius: 7px;
}

.contentTitle{
  font-weight: 700;
}
.content{
  width: 260px;
  height: 24px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.operations{
  display: flex;
  gap: 20px;
  justify-content: flex-end;

  margin-top: 20px;
}
