@value primaryScrollbar from '../../globalStyles.module.css';

.page {
  max-width: 1200px;
  margin: 0 auto;
}

.coreCompetenciesExpl {
  padding-top: 2rem;
  /* enable GPU */
  transform: translate3d(0, 0, 0);
}
ReflectList.test.jsx
.backtoDashboard {
  position: relative;
  color: var(--black3);
  font-size: 1rem;
  margin-top: 1rem;
  padding-left: 2rem;
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
}

.backtoDashboard::after {
  position: absolute;
  left: 0;
  content: '';
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-right: 1rem solid var(--black3);
}

.coreCompetenciesExplTitle {
  color: var(--black3);
  font-size: 20px;
  font-weight: bold;
}

.coreCompetenciesDes {
  color: var(--black3);
  font-size: 16px;
  margin-top:-12px;
  margin-bottom:25px;
}

.rectangle {
  margin: 36px auto;
  width: 129px;
  height: 7px;
  background-color: var(--primaryColor);
}

.coreCapabilitiesDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.coreCapabilitiesDescription a {
  text-decoration: underline;
  color: var(--primaryColor);
}

.subDesc {
  font-size: 16px;
  margin: 7px 0;
}

.subDesc > p {
  margin: 3px 0;
}

.competencyList {
  margin-top: 20px;
  overflow: hidden;
  box-sizing: border-box;
  padding-left: 17px;
  padding-bottom: 60px;
}

.fixedBtn {
  position: fixed;
  bottom: 40px;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
}

.disabledLink {
  pointer-events: none;
}
.pointer {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.filterList {
  margin-top: 17px;
  display: flex;
  justify-content: center;
}

.filterItem {
  width: 110px;
  border-radius: 20px;
  border: solid 1px var(--competencyFilterBorder);
  font-size: 14px;
  font-weight: 300;
  color: var(--grey80);
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  margin-right: 16px;
  box-sizing: border-box;
}

.noOutline:focus {
  outline: none;
}

.filterSelected {
  font-weight: bold;
  color: var(--black3);
  background-color: var(--competencyFilterBackgroud);
  border-color: var(--competencyFilterBorder);
}
.emptyFilterMsg {
  text-align: center;
  font-size: 18px;
  margin-top: 24px;
  color: var(--grey80);
}

.competencyGroup {
  overflow: hidden;
  border-radius: 7px;
  box-shadow: 0 2px 2px 0 var(--grey21);
  border: solid 1px var(--grey21);
  background-color: var(--white);
  margin-top: 28px;
}

.coreCompetenciesExplTitle + .competencyGroup {
  margin-top: 0;
}

.competencyGroupTitle {
  color: var(--black3);
  /* margin: 0; */
  background: var(--competencyGroupTitleBackgroud);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 14px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.inputWrapper {
  width: 370px;
}

.input {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  border-radius: 30px;
  font-size: 16px;
  padding: 12px 0 12px 50px;
  color: #808285;
  border: 1px solid var(--grey25);
}

.list {
  width: 370px;
  list-style: none;
  background: var(--white);
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 999;
  overflow: scroll;
  max-height: 460px;
}

.list > li {
  padding: 12px 20px;
  cursor: pointer;
}

.list > li:hover {
  background: #ced06e;
}

.icon {
  position: absolute;
  width: 26px;
  height: 26px;
  margin: 10px 0 0 14px;
}

.list > li.emptyList {
  text-align: center;
  padding: 40px 0;
  cursor: auto;
}

.list > li.emptyList:hover {
  background: inherit;
}

