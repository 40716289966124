@value primaryScrollbar from '../../../../globalStyles.module.css';

.archetypes {
  background-color: var(--white);
  border-radius: 7px;
  height: 438px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px var(--grey21);
  height: 61px;
  position: relative;
}

.headerInner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 18px 0 30px;
  height: 61px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: var(--grey80);
}

.archetypeList {
  padding: 20px 30px;
}

.scrollWrapper {
  height: 378px;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  margin-bottom: 7px;
}
