.title {
  margin: 20px 0;
  font-size: 16px;
  color: var(--black3);
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.medalContent, .medalContentInner {
  display: flex;
  color: var(--magenta);
  cursor: pointer;
}

.medalContentInner:focus {
  outline: none;
}

.medalContent span {
  padding-left: 4px;
  font-size: 16px;
}

.medalContent:hover .tooltipContainer {
  display: block;
}

.tooltipContainer {
  display:none;
  top: 46px;
  right: 0;
  z-index: 99;
  position: absolute;
  width: 254px;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(95, 95, 95, 0.26);
  background-color: rgba(0, 0, 0, 0.75);
}

.tooltipContainer:before{
  display: block;
  content:'';
  position:absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom:8px solid rgba(0, 0, 0, 0.75);
  right: 16px;
  top: -8px
}

.tooltipContainer p {
  font-size: 14px;
  color: white;
  margin: 0;
  padding: 12px;
}

.goalList {
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 32%;
  height: 510px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: var(--white);
  margin-bottom: 20px;
  margin-right: 2%;
  position: relative;
}

.card:nth-child(3n) {
  margin-right: 0;
}

.createGoalCard {
  position: relative;
  background-image: url(../../../assets/Icons/focus.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 345px 345px;
  cursor: pointer;
}

.createGoalCard p {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 57%;
  font-size: 20px;
  font-weight: bold;
  color: var(--magenta);
}

.createGoalCard:focus {
  outline-color: var(--magenta);
}

@media screen and (max-width: 992px) {
  .card {
    width: 49%;
  }

  .card:nth-child(3n) {
    margin-right: 2%;
  }

  .card:nth-child(2n) {
    margin-right: 0;
  }
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: var(--black3);
  font-size: 14px;
}

.left {
  padding-left: 25px;
  background-image: url(../../../assets/Icons/focus-3-line.png);
  background-position: 0 1px;
  background-repeat: no-repeat;
  background-size: 18px 18px;
}

.right {
  padding-left: 25px;
  background-image: url(../../../assets/Icons/history-fill.png);
  background-position: 0 1px;
  background-repeat: no-repeat;
  background-size: 18px 18px;
}

.option {
  color: var(--black3);
  font-size: 16px;
  margin: 0;
}

.selectWrapper{
  position: relative;
  padding-bottom: 10px;
}

.errorMsg {
  margin-left: 16px;
  color: var(--errorRed);
  font-size: 16px;
}

.learningGoalErrorMsg{
  position: absolute;
  bottom: -18px;
  margin-left: 12px;
  font-size: 14px;
  line-height: 28px;
}

.noCompetenciesMsg {
  text-align: left;
  width: 574px;
  margin: 0 auto;
  font-size: 20px;
  color: var(--black3);
}

.noCompetenciesMsg span{
  font-size: 16px;
  display: inline-block;
  margin-top: 10px;
}

.informationIcon {
  text-align: center;
  margin-top: 163px;
  margin-bottom: 24px;
}

.closeIcon {
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  margin-top: -8px;
  right: 14px;
  cursor: pointer;
}

.closeIcon img {
  width: 100%;
  height: 100%;
}

.alert {
  position: relative;
  width: auto;
  height: 48px;
  color: var(--white);
  background-color: var(--orange);
  padding: 6px 16px;
  font-size: 0.875rem;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
}

.cardHeader {
  position: relative;
  width: 100%;
  height: 44px;
  text-align: center;
  line-height: 44px;
  font-size: 16px;
  font-weight: bold;
  color: var(--black);
  padding: 0 50px;
  box-sizing: border-box;
}

.divder {
  width: 100%;
  height: 8px;
  background-color: var(--grey10);
}

.container {
  position: relative;
  margin-top: 22px;
  padding: 10px 20px;
  box-sizing: border-box;
  border: 1px solid var(--grey21);
  color: var(--black3);
  background-color: var(--white);
  border-radius: 7px;
  box-shadow: 1px 1px 2px 0 rgba(223, 223, 223, 0.5);
  font-size: 16px;
}

.line, .connectedLine {
  position: absolute;
  top: 158px;
  width: 66px;
  border-right: 2px solid var(--primaryGreen);
}

.line {
  top: 158px;
  bottom: -24px;
}

.connectedLine {
  top: -24px;
  height: 24px;
}

.unselectedLine {
  border-right: 2px solid var(--grey21);
}

.emptyGoalsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 246px;
}

.emptyGoalsContainer > .group {
  font-size: 64px;
}

.emptyGoalsContainer span {
  font-size: 20px;
  color: #a1a1a1;
  margin-top: 17px;
}

.emptyWithCurrentGoals {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 4px;
  border: solid 1px #1e90ff;
  background-color: rgba(30, 144, 255, 0.1);
  padding: 10px 45px;
  box-sizing: border-box;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 16px;
}

.emptyWithCurrentGoalsIcon {
  position: absolute;
  display: block;
  width: 18px;
  height: 18px;
  top: 50%;
  margin-top: -9px;
  left: 14px;
}

.emptyWithCurrentGoalsIcon img {
  width: 100%;
  height: 100%;
}
.contentContainer {
  margin-top: 40px;
  margin-bottom: 8px;
}

.ownGoalOption {
  display: flex;
}

.competencyGroupLink {
  align-items: center;
  text-decoration: none;
  color: var(--black);
  font-size: 14px;
  float: right;
  margin-top: 15px;
  display: flex;
}

.competencyGroupLink span {
  border-bottom: 1px solid;
}

.iconWrapper {
  width: 25px;
  height: 25px;
  background-color: var(--grey10);
  border-radius: 50%;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.competencyGroupLinkIcon {
  color: var(--black);
  font-size: 15px !important;
}
