.entryPoint {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 350px;
  border-radius: 7px;
  background-color: var(--white);
  background-repeat: no-repeat;
}
.courseRecommendations {
  height: 411px;
  border-radius: 0 0 7px 7px;;
}
.bg1 {
  background-image: url('../../assets/Pictures/HexgonBg1.png');
  background-position: center center;
}
.bg2 {
  background-image: url('../../assets/Pictures/HexgonBg2.png');
  background-position: center center;
}
.bg3 {
  background-image: url('../../assets/Pictures/SkillBg.png');
  background-position: center 150px;
}

.entryPointTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  color: var(--grey80);
}

.title {
  font-size: 30px;
  font-weight: bold;
}

.subTitle {
  font-size: 16px;
  color: var(--grey80);
}

.entryPointBtn {
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background-color: var(--magenta);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  cursor: pointer;
  position: relative;
}
.entryPointBtn:focus {
  outline: none;
}

.entryPointBtn:focus::after {
  border: 1px solid var(--primaryColor);
}

.entryPointBtnIcon {
  font-size: 40px !important;
}

.entryPointBtn::after {
  content: '';
  width: 88px;
  height: 88px;
  position: absolute;
  background: url('../../assets/Icons/iconBorder.png') center center;
}
.iconSpanInner{
  display: flex;
  align-items: center;
  width: 20px;
  padding-left: 5px;
}
.iconSpan {
  font-size: 20px;
  display: inline-block;
  line-height: 20px;
  vertical-align: middle;
}

.iconSpanInner:focus {
  outline: none;
}

.informationIcon {
  cursor: pointer;
  font-size: 20px !important;
  vertical-align: bottom;
}
