.meNextNotification {
  display: flex;
  justify-content:space-between;
  align-items: center;

  padding: 32px 20px 24px 24px;
  border-radius: 7px;
  background-color:var(--white);
}

.currentVisibilityCondition{
  display: flex;
}
.condition{
  margin-left: 16px;
}
.title{
  font-weight: 700;
}
.describe{
  margin-top: 5px;
  font-size: 14px;
}

.link{
  font-weight: 400;
  color: var(--pdfRead);
}

.preferenceEdit{
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 9px 16px;
  line-height: 22px;

  background-color: var(--black3);
  border:solid 1px var(--black3);
  border-radius: 7px;
  cursor:pointer
}

.editIcon{
  height: 24px;
  width: 24px;
}
.editWording{
  margin-left: 10px;
  color: var(--white);
}

.allDataVisible{
  background-color: var(--white);
  color:  var(--black3);
}
