.modal {
  width: auto;
  height: auto;
  background-color: var(--white);
  border-radius: 7px;
  outline: none;
  overflow-y: scroll;
}

.shareModal {
  width: auto;
  max-height: 910px;
  background-color: var(--white);
  border-radius: 7px;
  outline: none;
  overflow-y: scroll;
}

.modal::-webkit-scrollbar {
  width: 0;
}

.title {
  position: relative;
  width: 100%;
  height: 57px;
  background-color: var(--primaryColor);
  color: var(--black3);
  text-align: center;
  font-weight: bold;
  line-height: 57px;
  margin-top: 0px;
}

.title h2 {
  padding: 0;
  margin: 0;
  font-size: 20px;
}

.content {
  padding: 40px 20px 39px;
  flex-direction: column;
  display: flex;
  align-items: center;
  outline: none;
}

.desc {
  margin: 0 0 10px;
  font-size: 16px;
  color: var(--black3);
}

.desc p {
  margin: 10px 0;
}

.note {
  width: 100%;
  text-align: center;
  color: var(--black3);
  font-size: 20px;
  font-weight: bold;
}

.operations {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.close {
  position: absolute;
  right: 0;
  top: -3px;
  cursor: pointer;
  line-height: 0;
}

.closeInner:focus, .buttonInner:focus {
  outline: none;
}

