.switchMask{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
  height: 40px;
  width: 152px;
  border-radius: 20px;
  background-color:  rgba(255, 255, 255, 0.9);
}
.singleArchetypeName{
  display: flex;
  width: 232px;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  line-height: 30px;
  justify-content: center;
  color: var(--white);
}

.singleIntroduceWrapper {
  display: flex;
}

.singleIntroduceArchetypeWrapper {
  width: 312px;
}

.singleIntroduceArchetype {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 30px;
  width: 100%;
  min-height: 209px;
  gap: 20px;
  border-radius: 7px;
  background-color: var(--grey80);
  background-image: url(/src/assets/Pictures/ArchetypesSelectPageBg.png);
  background-size: 170px;
  background-position: -45px -5px;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.selectedIntroduceArchetype{
  background-color: var(--primaryGreen);
}

.singleIntroduceArchetypeDescription,
.introduceArchetypeDescription {
  color: var(--black);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.singleIntroduceArchetypeDescription {
  flex: 1;
  width: 0;
  margin: 0 42px 0 20px;
}

.singleIntroduceArchetypeDescription p {
  margin: 0;
}

.introduceArchetypeDescription p {
  margin: 0;
  display: inline;
  text-transform: lowercase;
}

.checkIcon {
  font-size: 30px !important;
  font-weight: bold;
}

.tabPanel {
  background-color: var(--grey10);
  min-height: 545px;
  padding: 10px 0;
}

.outcomesWrapper {
  margin: 20px auto 0 auto;
  width: 50%;
  color: var(--grey80);
  font-size: 16px;
}

.outcomesWrapper > li {
  padding: 5px 0;
}

.outcomesNotes {
  padding: 42px 10px;
  background-color: var(--white);
  color: var(--black);
  font-size: 16px;
  text-align: center;
}

.topCourses {
  margin: 10px 16px 10px 20px;
}

.topCoursesText {
  color: var(--black);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.topCoursesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-inline-start: 0;
  margin-bottom: 0;
}

.topCoursesItem {
  list-style-type: none;
  width: 292px;
  height: 262px;
}

.textCenter {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.inheritedArchetypeWrapper {
  padding: 0 150px;
  text-align: center;
}

.inheritedIntroduceArchetype {
  display: flex;
  position: relative;
}

.singleOutcomesWrapper {
  color: var(--grey80);
  font-size: 16px;
  padding: 0 60px;
  overflow-y: scroll;
}

.outcomeBoard {
  border: dotted 1px #cad3c8;
  margin: 40px auto 0 auto;
  width: 88%;
  padding: 0 15px;
}

.archetypeTitle {
  border-radius: 7px;
  background-color: var(--grey41);
  text-align: center;
  color: var(--white);
  font-weight: bold;
  padding: 6px;
  margin: 15px 40px;
}

.addIconWrapper {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.addIcon {
  font-size: 40px !important;
  font-weight: bold;
}

.firstArchetypeMargin {
  margin-right: 10px;
}

.lastArchetypeMargin {
  margin-left: 10px;
}

.basedArchetype {
  background-color: var(--avocadoGreen);
  color: var(--black);
}

.baseArchetypeEntry {
  background-color: var(--grey10);
  padding-top: 10px;
  text-align: center;
  font-size: 14px;
}

.baseArchetypeLink {
  cursor: pointer;
  color: var(--archetypeActive);
  position: relative;
  font-weight: 600;
}

.baseArchetypeLink::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 1px;
  background-color: var(--archetypeActive);
}

.baseArchetypeLinkInner:focus {
  outline: none;
}

.indicatorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.indicator {
  background-color: var(--grey80);
  border: 1px solid var(--grey80);
  padding: 7px 35px;
  margin: 0 7px;
  border-radius: 27.5px;
  font-size: 16px;
  color: var(--white);
}

.indicator.based {
  background-color: var(--grey21);
  color: var(--grey80);
  border-color: var(--grey40);
}
