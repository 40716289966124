.headerBar {
  width: 100%;
  background: var(--primaryColor);
  display: block;
  height: 80px;
  line-height: 80px;
}

.wrapper {
  display: flex;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}

.left {
  display: flex;
}

.growthCanvas {
  position: relative;
  text-align: center;
  margin-left: var(--space6);
}

.growthCanvasAfter > a {
  font-weight: bold;
}

.growthCanvasAfter:after {
  position: absolute;
  top: 52px;
  left: 58px;
  content: "";
  width: 0;
  height: 0;
  border: 14px solid transparent;
  border-bottom: 20px solid var(--grey4);
}

.growthCanvas > a {
  text-decoration: none;
  font-size: 16px;
  color: var(--black);
}

.right {
  display: flex;
  height: 100%;
  align-items: center;
}

.growthPath {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: var(--space6) ;
  color: var(--black);
  font-size: 16px;
  cursor: pointer;
}

.growthPath:hover .discoverLinkList {
  display: block;
  margin-right: -20px;
}

.guideShow .growthPath:hover .discoverLinkList, .guideShow .growthPath:focus-within .discoverLinkList {
  display: none;
}

.isTopCourseGuideShow .discoverLinkList {
  display: block;
  margin-right: -20px;
}

.growthPath:focus {
  outline: none;
}

.growthPath .circleArrow {
  display: flex;
  width: 30px;
  height: 30px;
  margin-left: var(--space4);
  border-radius: 50%;
  background-color: var(--competencyLevel);
  justify-content: center;
  align-items: center;
}

.arrowDown {
  transition: transform 0.3s !important;
}

.growthPath:hover .arrowDown, .growthPath:focus-within .arrowDown {
  transform: rotate(-180deg);
}

.guideShow .growthPath:hover .arrowDown, .guideShow .growthPath:focus-within .arrowDown {
  transform: rotate(0deg);
}

.discoverLinkList {
  display: none;
  position: absolute;
  top: 70px;
  right: 4px;
  z-index: 100000;
  width: 240px;
  height: auto;
  border-radius: 7px;
  background-color: var(--grey10);
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.link {
  display: flex;
  width: 100%;
  line-height: 50px;
  padding: 0 15px;
  box-sizing: border-box;
  align-items: center;
  border-bottom: solid 1px var(--grey21);
}

.link:hover, .link:focus-within {
  background-color: #ced06e;
}

.link:last-child {
  border-bottom: none;
}

.link a {
  width: 100%;
  font-size: 16px;
  color: var(--black3);
  text-decoration: none;
}

.link a:focus {
  outline: none;
}

.link .linkIcon {
  width: 15px;
  height: 15px;
  margin-left: 7px;
}

.toolTipButton {
  text-transform: none;
  border: 1px solid #404C58;
  border-radius: 7px;
}

.toolTipButton:hover {
  background: #591766;
}

.userName {
  color: var(--black);
  white-space: nowrap;
}

.userNameRole,
.userNameGrade {
  font-size: 16px;
  line-height: 26px;
}

.userNameRole {
  font-weight: bold;
}

.tourButton,
.goJigsawButton {
  display: flex;
  align-items: center;
  width: 170px;
  height: 32px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 32px;
  color: var(--white)
}

.tourButton {
  margin-top: 20px;
  background: rgba(38, 189, 207, 0.8);
  cursor: pointer;
}

.tourButton:hover {
  background: rgba(38, 189, 207, 1);
}

.goJigsawButton {
  margin-top: 8px;
  padding: 0 0 0 6px;
  box-sizing: border-box;
  background-color: var(--jigsaw);
  text-decoration: none;
}

.goJigsawButton:hover {
  background-color: var(--jigsawHover);
}

.goJigsawButton span {
  padding-left: 1px;
}
