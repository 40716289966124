.tabs {
  height: 60px;
  line-height: 60px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  position: relative;
  gap: 10px;
}
.tabs::after {
  content: '';
  height: 5px;
  width: 100%;
  display: block;
  background-color: var(--magenta);
  position: absolute;
  left: 0;
  bottom: 0;
}
.tabItem {
  height: 100%;
  flex: 1;
  width: 0;
  border-radius: 7px;
  background-color: #dfe4ea;
  color: var(--grey80);
  font-size: 16px;
  cursor: pointer;
}
.tabItem:first-child {
  margin-left: 0;
}
.tabItem:last-child{
  margin-right: 0;
}
.tabItem.active {
  background-color: var(--magenta);
  color: var(--white);
  font-weight: bold;
}
.tabItemInner:focus {
  outline: none;
}
