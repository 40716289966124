.page {
  max-width: 900px;
  margin: 0 auto;
  box-sizing: border-box;
}

.tips {
  position: relative;
  max-width: 1200px;
  margin: 20px auto;
}
.tips > p, h3 {
  margin: 9px 0;
  color: var(--black3);
}
.tipTitle {
  font-size: 20px;
  font-weight: bold;
}
.tipContent {
  font-size: 16px;

}

.dude {
  width: 119px;
  height: 168px;
  margin: 0 auto;
  transform: perspective(0px);
}
.flip {
  transform: rotateY(180deg);
}
.dude img {
  width: 100%;
  height: 100%;
}

.dudes {
  position: relative;
  width: 250px;
  height: 168px;
}

.dudes img {
  position: absolute;
  display: inline-block;
  width: 119px;
  height: 168px;
}

.dudes img:nth-child(1) {
  left: 30px;
  top: 0;
}

.dudes img:nth-child(2) {
  right: 20px;
  top: 0;
}

.nodata {
  font-size: 16px;
  color: var(--black3);
  text-align: center;
  margin-top: 20px;
}


.archetypeGroups {
  padding-bottom: 20px;
  display: flex;
  width: 100%;
  background-color: var(--grey4);
}

.cardWrapper {
  width: 100%;
  margin: 0 auto;
}

.carouselPage {
  outline: none;
}

.tipWrapper {
  display: inline-block;
  float: right;
  margin-top: -10px;
}




.forAxeCheck {
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
