.consultingBgColor {
  background-color: var(--lightBlue);
}

.cultivationBgColor {
  background-color: var(--avocadoGreen);
}

.impactBgColor {
  background-color: var(--orange);
}

.leadershipBgColor {
  background-color: var(--pink);
}

.archetypeBgColor {
  background-color: var(--grey40);
}

.noBelongsToCarBgColor {
  background-color: var(--grey40);
}

.pdfBorderColor {
  background-color: var(--grey70);
}

.pdfColor {
  background-color: var(--grey70);
}
