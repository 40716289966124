
.pathwaysIntroduceTitle{
  font-size: 20px;
  margin: 11px 0 0 0;
  font-weight: normal;
}

.pathwaysIntroduce{
  margin: 8px 0 0 0 ;
  font-size: 16px;
}

.cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card {
  width: calc( (100% - 60px) / 3 );
  height: 410px;
  border-radius: 7px;
  background-color: var(--white);
  padding: 25px;
  margin-top: 60px;
  position: relative;
  box-sizing: border-box;
  min-width: 380px;
}

.card:nth-of-type(2){
  margin-left: 30px;
  margin-right: 30px;
}


.cardTitle{
  margin: 144px 0 7px 0;
  font-size: 20px;
  line-height: 1.5;
  color: var(--black3);
}

.description{
  line-height: 1.88;
  letter-spacing: 0.5px;
  color: var(--black3);
  box-sizing: border-box;
  margin: 0;
}

.marginRight73 {
  margin-right: 73px;
}

@media screen and (max-width: 1200px) {
  .card {
    width: calc((100% - 30px) / 2);
    height: auto;
  }

  .card:nth-of-type(2) {
    margin: 60px 0 0 30px;
    height: auto;
  }

  .pathwaysIntroduceTitle, .pathwaysIntroduce {
    padding: 0 25px;
  }
}

@media screen and (max-width: 800px) {
  .card {
    width: 100%;
    height: auto;
  }
  .card:nth-of-type(2){
    margin: 60px 0 0 0;
  }
  .module1Icon{
    right: 0;
  }
}
