.period {
    display: flex;
    font-weight: bold;
    font-size: 16px;
    padding-left: 10px;
    box-sizing: border-box;
    align-items: center;
}

.period span i {
    color: var(--errorRed);
    font-style: normal;
    font-weight: normal;
}

.errorMessage {
    padding-left: 18px;
    font-weight: normal;
    font-size: 14px;
    color: var(--errorRed);
    line-height: 22px;
}

.periodError{
    padding-left: 36px;
}

.notificationDot{
    color: var(--errorRed);
    position: absolute;
    margin-left: 4px;
    margin-top: 10px;
}

.informationContainer {
    display: flex;
    justify-content: space-between;
}

.unselectedHexagonView {
    position: relative;
    -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    overflow: hidden;
    display: inline-block;
    width: 134px;
    height: 150px;
    background-color: var(--grey21);
    cursor: default;
    transition: transform .3s;
}
.unselectedHexagonViewAfter {
    position: absolute;
    left: 2px;
    top: 2px;
    width: 130px;
    height: 146px;
    clip-path: polygon(0 25%,50% 0,100% 25%,100% 75%,50% 100%,0 75%);
    z-index: -1;
    background-color: var(--grey10);
}

.unselectedMessage {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    position: absolute;
    top: 60px;
    text-align: center;
    color: var(--black3);
    width: 80px;
    left: 27px;
}

.hexagonView {
    position: relative;
    -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    overflow: hidden;
    display: inline-block;
    width: 134px;
    height: 150px;
    background-color: var(--primaryGreen);
    cursor: default;
    transition: transform .3s;
}

.hexagonViewAfter {
    position: absolute;
    left: 2px;
    top: 2px;
    width: 130px;
    height: 146px;
    -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    z-index: -1;
    background-color: var(--white);
}

.hexagonContent {
    width: 130px;
    height: 76px;
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    color: var(--black3);
    font-size: 12px;
    line-height: normal;
    letter-spacing: normal;
}

.competencyName {
    font-weight: bold;
    min-height: 70%;
    padding: 0 10px;
}

.competencyLevel {
    margin-top: 3px;
    color: var(--black);
    width: 100%;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
}

.competencyLevel > span {
    background-color: var(--competencyLevel);
    padding: 1px 6px;
    border-radius: 5px;
}

.line {
    width: 87px;
    height: 22px;
    border-right: 2px solid var(--primaryGreen);
}

.displayDescription {
    width: 520px;
    padding: 10px;
    word-break: break-word;
}

.ownGoalView {
    width: 144px;
    height: 144px;
    border-radius: 7px;
    border: solid 2px var(--primaryGreen);
    background: url("../../../../assets/Icons/Diana.svg") no-repeat center 14px;
}

.ownGoalViewContent {
  width: 180px;
  margin-top: 117px;
  margin-left: -18px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  transform:scale(0.9);
}
