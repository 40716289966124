@value primaryScrollbar from '../../globalStyles.module.css';

.page {
  max-width: 1200px;
  margin: 0 auto;
}

.skillsExpl {
  padding-top: 2rem;
  /* enable GPU */
  transform: translate3d(0, 0, 0);
}

.backtoDashboard {
  position: relative;
  color: var(--black3);
  font-size: 1rem;
  margin-top: 1rem;
  padding-left: 2rem;
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
}

.backtoDashboard::after {
  position: absolute;
  left: 0;
  content: "";
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-right: 1rem solid var(--black3);
}

.skillsExplTitle {
  color: var(--black3);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 7px;
}

.description {
  font-size: 16px;
  color: var(--black3);
  margin: 0 0 20px;
}

.rectangle {
  margin: 36px auto;
  width: 129px;
  height: 7px;
  background-color: var(--primaryColor);
}

.bold {
  font-weight: bold;
}

.coreCapabilitiesDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

}

.coreCapabilitiesDescription a {
  text-decoration: underline;
  color: var(--primaryColor);
}

.subDesc {
  font-size: 16px;
  margin: 7px 0;
}

.subDesc > p {
  margin: 3px 0;
}

.skillList {
  margin-top: 20px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 20px 20px;
}

.skillPod {
  margin: 7px;
  border-radius: 999px;
  border: none;
  background-color: var(--grey81);
  padding: 7px 14px;
  line-height: 20px;
  color: var(--black3);
  cursor: pointer;
  font-size: 16px;
}

.skillPod:hover {
  background-color: var(--grey20);
}

.skillPod.selected {
  color: white;
  background-color: var(--black3);
}

.fixedBtn {
  position: fixed;
  bottom: 40px;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
}

.disabledLink {
  pointer-events: none;
}

.tipContent {
  height: 600px;
  width: 660px;
  border-radius: 7px;
  background-color: var(--white);
  padding: 20px 30px;
  box-sizing: border-box;
  position: relative;
}


.bold {
  font-weight: bold;
}

.capabilities {
  margin-top: 10px;
}

.pointer {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.capItem {
  line-height: 21px;
  color: var(--grey80);
  margin-top: 30px;
}

.capItem p:nth-child(1) {
  display: inline;
}


.filterList {
  margin-top: 17px;
  display: flex;
  justify-content: center;
}

.filterItem {
  width: 110px;
  border-radius: 20px;
  border: solid 2px var(--grey80);
  font-size: 14px;
  font-weight: 300;
  color: var(--grey80);
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  margin-right: 16px;
  box-sizing: border-box;
}


.noOutline:focus {
  outline: none;
}

.filterSelected {
  font-weight: bold;
  color: var(--white);
  background-color: var(--black3);
  border-color: var(--black3);
}

.tipContentMain {
  box-sizing: border-box;
  height: 410px;
  width: 556px;
  overflow: auto;
  display: flex;
}

.tipModalIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--grey41);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 10px;
}

.tipModalIcon > img {
  height: 30px;
  width: 24px;
}

.emptyFilterMsg {
  text-align: center;
  font-size: 18px;
  margin-top: 24px;
  color: var(--grey80);
}

.capabilitiesDesc p {
  margin: 5px 0 0;
}

.skillGroup {
  overflow: hidden;
  border-radius: 7px;
  box-shadow: 0 2px 2px 0 var(--grey21);
  border: solid 1px var(--grey21);
  background-color: var(--white);
  margin-top: 28px;
}

.skillGroupTitle {
  position: relative;
  color: var(--white);
  margin: 0;
  background: var(--black3);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 14px 0;
}

.tipWrapper {
  color: var(--white);
}

.tipTrigger {
  position: absolute;
  top: 6px;
  right: 15px;
}

.tipTrigger > .ovalText {
  color: var(--white);
  border-bottom-color: var(--white);
}

.search {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
