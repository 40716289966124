.competency {
  position: relative;
  transition-duration: 0.4s;
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  font-size: 20px;
  outline: none;
}

.borderUnselected {
  background-color: var(--competencyHexagonBorder);
}

.borderSelected {
  background-color:transparent;
}

.competency .competencyContent {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 4px);
}

.addIcon {
  width: 24px;
  height: 24px;
}

.competencyContentSelected {
  color: var(--black);
}

.nameOuter {
  display: flex;
  align-items: center;
  height: 68px;
}

.name:focus {
  outline: none;
}

.competencyInfoIconWrapperInner {
  height: 24px;
  outline: none;
}

.competencyHoverable:hover .competencyInfoIconWrapper,
.competencyHoverable:hover .competencyAddIconWrapper,
.competencyHoverable:hover .competencyDeleteIconWrapper {
  opacity: 1;
}

.competencyHoverable:hover .competencyEditIconWrapper,
.competency:focus-within .competencyEditIconWrapper {
  display: block;
}

.competencyInfoIconWrapper,
.competencyAddIconWrapper,
.competencyDeleteIconWrapper {
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.competencyInfoIconWrapper {
  margin-top: 20px;
}
.competencyAddIconWrapper,
.competencyDeleteIconWrapper {
  margin-bottom: 17px;
}
.competencyInfoIconWrapper:hover,
.competencyAddIconWrapper:hover,
.competencyDeleteIconWrapper:hover {
  opacity: 1;
}

.competencyInfoIcon {
  color: var(--black);
}

.name:hover {
  text-decoration: underline;
}

.noUnderline:hover {
  text-decoration: none;
}

.competencyInfoIcon:focus {
  outline: none;
}

.competencyEditIconWrapper {
  display: none;
  position: absolute;
  bottom: 3px;
  line-height: 0;
  left: 50%;
  transform: translateX(-50%);
}

.competencyEditIcon {
  font-size: 20px !important;
}

.competency .competencyContent .name {
  box-sizing: border-box;
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  width: 100%;
  line-height: 16px;
}

.competencyAfter {
  position: absolute;
  left: 2px;
  top: 2px;
  content: '';
  background-color: var(--competencyHexagonBackground);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  z-index: -1;
}

.competency:hover {
  transform: scale(0.95);
  background-color: var(--competencyHexagonSelectedBorder);
}

.noScale {
  cursor: default;
}

.noScale:hover {
  transform: scale(1);
}

.competency:hover .competencyDashboard {
  background-color: var(--primaryGreen);
  color: var(--white);
}

.selected {
  background-color: var(--competencyHexagonSelectedBackground);
}

.competencyRep {
  position: absolute;
  z-index: -1;
}

.proficiencyLevel {
  line-height: 1;
  font-size: 11px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  white-space: nowrap;
}

.proficiencyLevel > span {
  background-color: var(--competencyLevel);
  padding: 1px 6px;
  border-radius: 5px;
}

.noIcon {
  height: 25px;
}
