.inputWrapper {
    margin-top: 10px
}

.inputName {
    font-size: 16px;
    font-weight: bold;
    color: var(--black3);
    padding-bottom: 10px;
}

.inputDescription {
    font-size: 16px;
    color: var(--black3);
    padding-bottom: 10px;
}

.inputWithButton {
    position: relative;
}

.noItemInformation {
    display: flex;
    align-items: center;
    padding-top: 8px;
}

.noItemInformation > .informationIcon {
    font-size: 18px;
    color: var(--grey25);
    opacity: 0.56;
}

.noItemInformation span {
    font-style: italic;
    font-size: 16px;
    padding-left: 6px;
}

.inputList {
  max-height: none !important;
}

.listItem {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.hideButton {
    text-align: center;
    display: block;
    margin: 0 auto;
    border: none;
    background: none;
    font-size: 16px;
    color: #1a73ba;
    text-decoration-line: underline;
    cursor: pointer;
}

.item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itemLeft {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 70%;
    border-radius: 27.5px;
    background-color: var(--black);
    padding: 2px 5px;
    margin: 5px 0;
    word-break: break-word;
}

.greenBgc {
    background-color: var(--primaryGreen);
}

.itemLeft span {
    font-size: 16px;
    color: var(--white);
    margin: 6px 10px;
}

.checkbox {
    color: var(--grey25);
}

.greenColor {
    font-weight: 600;
    color: var(--primaryGreen);
}

.errorMessage {
    font-size: 16px;
    color: var(--errorRed);
    line-height: 30px;
    padding-left: 10px;
}

.deleteIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin: 4px 0;
    border-radius: 50%;
    color: var(--white);
    background-color: var(--magenta);
}

.deleteIconInner:focus {
    outline: none;
}

.addButton {
    position: absolute;
    right: 0;
    top: 0;
    height: 39px;
    border: 1px solid var(--grey21);
    border-radius: 0 30.5px 30.5px 0;
    border-left: none;
    width: 11%;
    text-align: center;
    display: flex;
    align-items: center;
    padding-right: 2px;
}
