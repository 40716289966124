.errorMessage {
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: var(--errorRed);
}

.inputContainer {
  display: flex;
  border: 2px solid var(--grey40);
  border-radius: 30.5px;
  padding: 2px;
}

.inputContainer:focus-within {
  border-color: var(--black3);
}

.inputContainer:hover {
  border-color: var(--black3);
}

