.communities {
    background-color: var(--white);
    padding-bottom: 20px;
    border-radius: 7px;
    height: 418px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 18px 0 30px;
    color: var(--grey80);
    border-bottom: solid 1px var(--grey21);
    position: relative;
    height: 60px;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 20px;
}

.title img {
    width: 35px;
    margin-right: 5px;
    margin-top: 13px;
}

.subTitle {
    position: absolute;
    left: 40px;
    right: 0;
    bottom: 2px;
    font-size: 16px;
    text-indent: 30px;
    color: var(--grey80);
    margin-bottom: 2px;
}

.chats {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 40px 20px;
}

.chat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 27.5px;
    background-color: var(--black);
    padding: 6px 10px;
    margin: 7px;
    text-decoration: none;
}

.chat.disabled {
    background-color: var(--lightGreen);
    cursor: auto;
}

.chat p {
    font-size: 12px;
    color: var(--white);
    margin: 0;
}

.chat.disabled p {
    color: var(--black);
}

.chat span:first-child {
    font-size: 16px;
    font-weight: 600;
}

.roomIcon {
    font-size: 20px !important;
    color: var(--white);
    margin-left: 10px;
}
