html {
  font-family: 'Open Sans', 'sans-serif';
}

.primaryBackgroundColor {
  background: var(--primaryColor);
}

.primaryScrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.primaryScrollbar::-webkit-scrollbar-track {
  border-left: 2px solid white;
  border-right: 2px solid white;
}

.primaryScrollbar::-webkit-scrollbar-thumb {
  background-color: #023d67;
  border-radius: 13px;
}

:root {
  --pageBackgroundColor: #f3f6f9;
  --primaryColor: #26bdcf;
  --magenta: #c22d65;
  --avocadoGreen: #b5b935;
  --primaryGreen: #0d8f82;
  --lightGreen: #e6fbfd;
  --aquaBlue: #00d8db;
  --lightBlue: #9bc8ec;
  --aquaBlueLight: rgba(25, 182, 185, 0.2);
  --blue1: #cceff2;
  --blue2: #003d4f;
  --blue3: rgba(27, 37, 58, .9);
  --primaryGrey: #94979b;
  --darkGrey: #cecece;
  --secondaryColor: #b6f4f4;
  --white: #fff;
  --black: #000;
  --black1: #000000;
  --black3: #212223;
  --black4: #2f3032;
  --grey2: #979797;
  --grey4: #f4f6f9;
  --grey10: #f0f1f1;
  --grey11: #e0e0e0;
  --grey20: #e9e9ea;
  --grey21: #e1e2e3;
  --grey23: #b8c1ce;
  --grey24: #a4b0be;
  --grey25: #9a9c9e;
  --grey40: #bdbec0;
  --grey41: #cad3c8;
  --grey70: #616364;
  --grey80: #414343;
  --grey81: #f4f4f4;
  --grey82: #edf1f3;
  --grey83: #31394d;
  --grey84: #c5c5c5;
  --grey85: #dcdcdc;
  --grey86: #e1e3ea;

  --orange: #f78f31;
  --orange2: #f29e87;
  --pink: #ef5ba1;
  --pink1: #f2617a;
  --errorRed: #ee312d;
  --pdfRead: #c43067;
  --hoverRed: #870536;
  --pdfBorder: #808285;
  --jigsaw: #591766;
  --jigsawHover: #7a2b8a;
  --competencyGroupTitleBackgroud: #26bdcf;
  --competencyFilterBackgroud: #93e7f2;
  --competencyFilterBorder: #26bdcf;
  --competencyHexagonBackground: #f5f6f9;
  --competencyHexagonBorder: #e1e3ea;
  --competencyHexagonSelectedBackground: #93e7f2;
  --competencyHexagonSelectedBorder: #26bdcf;
  --competencyModalMoreLinkBorder: #666666;
  --goalCreatedPlaceholder: #454545;
  /* core competencies flow basic color */
  --btnSecondary: #f8eebd;
  --basicGreen: #29b6b8;
  --archetypeActive: #1a73ba;
  --competencyLevel: #93e7f2;

  --red1: #c43067;

  --space1: 2px;
  --space2: 4px;
  --space3: 8px;
  --space4: 12px;
  --space5: 16px;
  --space6: 24px;
  --space7: 32px;
  --space8: 40px;
  --space9: 48px;
  --space10: 64px;
  --space11: 80px;
}

/* name of class cannot collide with name of values! New variable has to be mapped as per below */
@value primaryColor: var(--primaryColor);
@value white: var(--white);
@value magenta: var(--magenta);
@value avocadoGreen: var(--avocadoGreen);
@value primaryGreen: var(--primaryGreen);
@value aquaBlue: var(--aquaBlue);
@value lightBlue: var(--lightBlue);
@value blue1: var(--blue1);
@value blue2: var(--blue2);
@value blue3: var(--blue3);
@value primaryGrey: var(--primaryGrey);
@value darkGrey: var(--darkGrey);
@value secondaryColor: var(--secondaryColor);
@value black: var(--black);
@value black: var(--black1);
@value black3: var(--black3);
@value black4: var(--black4);
@value grey2: var(--grey2);
@value grey4: var(--grey4);
@value grey10: var(--grey10);
@value grey11: var(--grey11);
@value grey20: var(--grey20);
@value grey21: var(--grey21);
@value grey23: var(--grey23);
@value grey24: var(--grey24);
@value grey40: var(--grey40);
@value grey41: var(--grey41);
@value grey70: var(--grey70);
@value grey80: var(--grey80);
@value grey81: var(--grey81);
@value grey82: var(--grey82);
@value grey83: var(--grey83);
@value grey84: var(--grey84);
@value grey85: var(--grey85);
@value grey86: var(--grey86);
@value orange: var(--orange);
@value orange2: var(--orange2);
@value pink: var(--pink);
@value pink: var(--pink1);
@value errorRed: var(--errorRed);
@value pdfRead: var(--pdfRead);
@value hoverRed: var(--hoverRed);

/* core competencies flow variable */
@value btnSecondary: var(--btnSecondary);
@value basicGreen: var(--basicGreen);
@value archetypeActive: var(--archetypeActive);

@value red1: var(--red1);

@value jigsaw: var(--jigsaw);
@value jigsawHover: var(--jigsawHover);
@value goalCreatedPlaceholder: var(--goalCreatedPlaceholder);

@value space1: var(--space1);
@value space2: var(--space1);
@value space3: var(--space1);
@value space4: var(--space1);
@value space5: var(--space1);
@value space6: var(--space1);
@value space7: var(--space1);
@value space8: var(--space1);
@value space9: var(--space1);
@value space10: var(--space1);
@value space11: var(--space1);
