@value primaryScrollbar from '../../../../globalStyles.module.css';
.modal {
  width: 820px;
  background-color: var(--white);
  border-radius: 7px;
  outline: none;
  margin: 100px auto;
}

.title {
  position: relative;
  width: 100%;
  height: 57px;
  background-color: var(--primaryColor);
  display: flex;
  justify-content: center;
}

.centerlize {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: var(--grey80);
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #16a7b8;
  margin-right: 13px;
}

.icon img {
  width: 22px;
  height: 22px;
}

.close {
  position: absolute;
  right: 0;
  top: -3px;
  cursor: pointer;
}
.closeInner:focus {
  outline: none;
}

.content {
  padding: 16px 48px 0px;
}

.operations {
  width: 100%;
  box-sizing: border-box;
  padding: 0 48px 38px;
  overflow: hidden;
}

.buttonContainer{
  display: flex;
  padding-top: 20px;
  justify-content: flex-end;
  float: right;
}

.buttonContainer > .marginLeft{
  margin-left: 23px;
}


.accomplishLinkInfo > .badgeIcon{
  width: 65px;
  height: 86px;
}
.accomplishLinkInfo{
  display: flex;
  float: left;
  margin-left: 55px;
}
.makeAccomplish{
  margin-left: 20px;
  line-height: 86px;
  font-size: 16px;
  color: var(--archetypeActive);
  text-decoration-line: underline;
  cursor: pointer;
}
.makeAccomplishInner:focus{
  outline: none;
}
.hasAccomplished{
    margin-left: 20px;
    line-height: 86px;
    font-size: 16px;
}
