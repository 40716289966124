.knowPathwaysMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 806px;

}

.knowPathwaysImage{
  width: 171px;
  height: 176px;
  border-radius: 50%;
}
.describeTitle{
  width: 436px;
  padding-top: 24px;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: var(--pdfRead);
}
.describeInfo{
  width: 436px;
  padding-top: 26px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--black);
}

.knowPathwaysBtns{
  display: flex;
  justify-content: flex-end;
  width: 806px;
  padding-top: 20px;
}
.skipTourBtn{
  text-decoration-line: underline;
  color: var(--black3);
  line-height: 50px;
  cursor: pointer;
}
.takeQuickTourBtn{
  width: 200px;
  height: 50px;
  margin-left: 25px;
  background: var(--pdfRead);
  box-shadow: 0px 2px 4px rgba(95, 95, 95, 0.5);
  border-radius: 7px;
  font-weight: bold;
  color: var(--white);
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}
