.archetypeCard {
  width: 280px;
  margin: 0 10px;
}

.archetypeCardHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  background-color: var(--primaryGreen);
  border-radius: 6px;
  color: var(--white);
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  padding: 0 50px;
  background-image: url(/src/assets/Pictures/ArchetypesSelectPageBg.png);
  background-size: 56px;
  background-position: -15px -1px;
  background-repeat: no-repeat;
  position: relative;
}
.archetypeCardHeader span {
  width: 180px;
  font-family: 'Open Sans', 'sans-serif';
  letter-spacing: normal;
  line-height: 16px;
}

.radioLabel {
  color: var(--grey80);
  font-size: 16px;
  margin: 15px 0;
  font-family: 'Open Sans', 'sans-serif';
  letter-spacing: normal;
}
