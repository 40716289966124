.model {
  width: 768px;
  border-radius: 7px;
  background-color: var(--white);
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.model:focus {
  outline: none;
}
.modalBody {
  padding: 0 20px 20px 20px;
}
.modalHeader {
  flex: 1;
  width: 0;
  text-align: center;
  color: #000000 !important;
}
.editIcon {
  font-size: 24px !important;
  vertical-align: -0.2em;
  margin-right: 0.1em;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 40px;
  width: 40px;
  background-color: var(--grey21);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeInner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeInner:focus {
  outline: none;
}

.disabled {
  background-color: var(--grey70);
  cursor: not-allowed;
  pointer-events: none;
}

.titleWrapper {
  padding: 20px 0;
  height: 60px;
  display: flex;
  align-items: center;

}

.title {
  height: 100%;
  padding: 10px 0;
  width: auto;
  display: inline-flex;
  word-wrap: break-word;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #191919;
}

.actionBar {
  text-align: right;
  margin-top: 50px;
  padding-right: 16px;
  padding-bottom: 30px;
}

.marginLeft {
  margin-left: 16px !important;
}

.proficiencyLevelItem {
  font-size: 16px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.proficiencyLevel {
  width: 72%;
  display: flex;
  gap: 30px;
  margin-bottom: 18px;
}

.informationIconWrapper {
  margin: 1px 0 0 15px;
  cursor: pointer;
  height: auto;
}
.informationIcon {
  font-size: 20px !important;
}
.removeButton {
  display: flex;
  flex-direction: row;
  align-items: center;

  float: left;
  background: none;
  border: none;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  color: var(--grey80);
}
.confirmInfo {
  float: left;
  font-size: 14px;
  color: #000000;
  width: 400px;
  text-align: left;
}
.confirmTitle{
  font-weight: bold;
  float: left;
  font-size: 16px;
  color: #E64620;
  cursor: pointer;
  text-decoration: underline;
}

.removeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;

  width: 24px;
  height: 24px;
}

.removeText {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.clearButtonInner:focus {
  outline: none;
}
.deleteButtonIcon {
  color: var(--black) !important;
}
.lineWrapper {
  margin: 0 -20px;
  padding: 0 20px 0 30px;
  background-color: var(--grey10);
}

.loadingMask {
  background-color: var(--grey23);
  width: 768px;
  height: 480px;
  position: absolute;
  z-index: 9999;
  opacity: 0.4
}
