.contentContainer {
  margin-bottom: 8px;
}
.cards{
  margin-top: 23px !important;
}
.contentTitle{
  display: flex;
  align-items: center;
}
.DashboardContainer {
  padding-top: 50px;
}
