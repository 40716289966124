.steps {
    height: 100%;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0 250px;
    display: flex;
    align-items: center;
}
.stepItem {
    flex: 1;
    height: 100%;
    width: 0;
}
.stepItem:last-child .line {
    display: none;
}
.stepItemInner {
    height: 100%;
    width: 100%;
    border-radius: 0;
    position: relative;
}
.dot.active::after {
    background-color: var(--primaryColor);
}
.dot {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: var(--white);
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
}
.dot::after {
    content: '';
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    background-color: var(--grey10);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.line.active {
    background-color: var(--primaryColor);
    box-shadow: none;
}
.line {
    height: 4px;
    box-shadow: 0 2px 4px 0 #dbe0eb;
    background-color: var(--white);
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(0, -50%);
    right: -50%;
    z-index: 0;
}
.title.active {
    color: var(--grey80);
    font-weight: 600;
}
.title {
    margin-top: -28px;
    font-size: 14px;
    text-transform: none;
    text-align: center;
    color: #616364;
    position: relative;
    z-index: 2;
}
.title span {
    outline: none;
}
.informationText {
    font-size: 14px;
    margin: 0;

}
.informationIcon {
    margin-left: 5px;
    cursor: pointer;
    font-size: 16px !important;
    position: relative;
    top: 3px;
}
@media screen and (max-width: 768px) {
    .title {
        display: none;
    }
    .dot {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .line {
        top: 50%;
        left: 50%;
        transform: translate(0, -50%);
    }
}
.clickable {
    cursor: pointer !important;
}
